import { MutableRefObject, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { Provider, useContentArea } from "../ContentArea/content-area-context";
import { getWidgetRegex, WidgetTypes } from "./index";
import Widget from "./Widget";

const useInitialWidgets = (
  contentRef: MutableRefObject<HTMLDivElement | null>,
  initialRawHTMLContent: string,
  readOnly: boolean,
  isXrayMode: boolean,
  updates?: number,
) => {
  const content = useContentArea();
  const processedRef = useRef(false);

  useEffect(() => {
    processedRef.current = false;
  }, [updates]);

  useEffect(() => {
    if (contentRef.current && !processedRef.current) {
      let processedContent = initialRawHTMLContent;

      Object.values(WidgetTypes).forEach((widgetType) => {
        const widgetRegex = new RegExp(getWidgetRegex(widgetType), "g");

        processedContent = processedContent.replace(widgetRegex, (match) => {
          const componentId = uuidv4();
          const componentNode = document.createElement("section");
          componentNode.setAttribute("data-custom-component-id", componentId);
          componentNode.setAttribute("data-widget-type", widgetType);
          componentNode.setAttribute("data-widget-match", match);
          componentNode.setAttribute("contenteditable", "false");
          componentNode.style.display = "inline-block";
          componentNode.style.whiteSpace = "nowrap";
          componentNode.style.verticalAlign = "baseline";
          return componentNode.outerHTML;
        });
      });

      contentRef.current.innerHTML = processedContent;

      // Render React components into the sections
      contentRef.current
        .querySelectorAll("section[data-custom-component-id]")
        .forEach((section) => {
          const componentId = section.getAttribute("data-custom-component-id");
          const widgetType = section.getAttribute(
            "data-widget-type",
          ) as WidgetTypes;
          const widgetMatch = section.getAttribute("data-widget-match");

          if (componentId && widgetType && widgetMatch) {
            const root = createRoot(section);
            root.render(
              <Provider value={content}>
                <Widget
                  id={componentId}
                  type={widgetType}
                  match={widgetMatch}
                  readOnly={readOnly}
                  isXrayMode={isXrayMode}
                />
              </Provider>,
            );
          }
        });

      // Emulate initial data entry as an input event
      const inputEvent = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      contentRef.current.dispatchEvent(inputEvent);

      processedRef.current = true;
    }
  }, [contentRef, initialRawHTMLContent, content, readOnly, isXrayMode]);
};

export default useInitialWidgets;
