import { PromptVersionSnippet } from "@/types/apiGetters";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TextareaProps } from "../ui/textarea";
import Content from "./Content";
import { ContentAreaProvider } from "./content-area-context";
import { ITextAreaStore } from "./store";
import { escapeXml } from "./utils";

const ContentArea = (
  props: TextareaProps & {
    isXrayMode?: boolean;
    sourcedSnippets?: Array<PromptVersionSnippet>;
  },
) => {
  const contentAreaRef = useRef<ITextAreaStore | null>(null);
  const [updates, setUpdates] = useState({ external: 0, direct: 0 });
  const wasXrayMode = useRef(props.isXrayMode);

  const checkForChanges = useCallback(() => {
    if (!contentAreaRef.current) return;

    const didXrayModeChange = props.isXrayMode !== wasXrayMode.current;
    const didValueChange = props.value !== contentAreaRef.current.getContent();

    if (didXrayModeChange) {
      wasXrayMode.current = props.isXrayMode;
      setUpdates((prev) => ({ ...prev, direct: prev.direct + 1 }));
    }
    if (didValueChange) {
      setUpdates((prev) => ({ ...prev, external: prev.external + 1 }));
    }
  }, [props.isXrayMode, props.value]);

  useEffect(() => {
    checkForChanges();
  }, [checkForChanges]);

  const escapedValue = useMemo(() => escapeXml(props.value), [props.value]);

  return (
    <ContentAreaProvider
      key={updates.direct}
      externalUpdates={updates.external}
      ref={contentAreaRef}
      sourcedSnippets={props.sourcedSnippets}
      value={escapedValue}
    >
      <Content {...props} />
    </ContentAreaProvider>
  );
};

export default ContentArea;
