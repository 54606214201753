import TagInput from "@/components/ConditionalModal/TagInput";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../select";

interface RestrictedKeyValuePairProps {
  keys: string[];
  onChange: (key: string, value: string[]) => void;
  placeholder?: string;
}

const RestrictedKeyValuePair = ({
  keys,
  onChange,
  placeholder = "Select a key",
}: RestrictedKeyValuePairProps) => {
  const [selectedKey, setSelectedKey] = useState<string>("");

  return (
    <div className="flex flex-col gap-2">
      <Select onValueChange={setSelectedKey} value={selectedKey || undefined}>
        <SelectTrigger className="w-[200px] bg-white">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent
          position="popper"
          className="max-h-[200px] overflow-auto"
        >
          {keys.map((key, index) => (
            <SelectItem key={index} value={key}>
              {key}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {selectedKey && (
        <TagInput
          initialTags={[]}
          onTagsChange={(list) => {
            onChange(selectedKey, list);
          }}
        />
      )}
    </div>
  );
};

export default RestrictedKeyValuePair;
