import { LockClosedIcon } from "@radix-ui/react-icons";

const UpgradeRequired = () => (
  <div className="m-4 rounded-md border border-gray-200 bg-gray-50 p-4">
    <p className="pb-2 text-lg font-semibold">
      <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
      Upgrade Required
    </p>
    <p className="text-md">
      To generate a full evaluation report, your workspace admin must upgrade
      your payment plan to "Startup" or "Team". Do this in settings on the top
      right corner of your screen.
    </p>
  </div>
);

export default UpgradeRequired;
