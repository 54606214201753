import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import logo from "@/assets/logo/promptlayer-cake-color.svg";
import { useResetPassword } from "@/api/application-api";
import AuthSubmitButton from "@/components/AuthSubmitButton";
import { FormInput } from "@/components/ui/FormInput";
import { resetPasswordSchema } from "./schema";
import { resetPasswordFormFields } from "./constants";
import { ToastType } from "@/enums";
import { displayToast } from "@/utils/toast";
import { Button } from "@/components/ui/button";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: resetPassword, isLoading: isResetPasswordLoading } =
    useResetPassword();
  const urlParams = new URLSearchParams(location.search);
  const emailFromURL = decodeURIComponent(urlParams.get("email") || "");

  const form = useForm<z.infer<typeof resetPasswordSchema>>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      email: emailFromURL,
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(resetPasswordSchema),
  });

  const onSubmit = async (values: z.infer<typeof resetPasswordSchema>) =>
    resetPassword(
      {
        code: urlParams.get("twilio_code") || "",
        email: values.email,
        password: values.password,
      },
      {
        onSuccess: (data) => {
          displayToast(data.message, ToastType.success);
          navigate("/login");
        },
      },
    );

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-2">
        <img src={logo} alt="logo" className="h-[52px] w-[52px]" />
        <span className="text-3xl font-bold text-gray-900">Reset Password</span>
      </div>
      <form
        className="flex flex-col gap-3 py-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {resetPasswordFormFields.map((field) => (
          <FormInput
            {...form.register(
              field.name as keyof z.infer<typeof resetPasswordSchema>,
            )}
            key={field.name}
            label={field.label}
            placeholder={field.placeholder}
            type={field.type}
            error={
              form.formState.errors[
                field.name as keyof z.infer<typeof resetPasswordSchema>
              ]?.message
            }
          />
        ))}
        <div className="flex flex-col items-center gap-2">
          <AuthSubmitButton
            loading={isResetPasswordLoading}
            disabled={!form.formState.isValid}
            text="Reset Password"
          />
          <Button variant="inlineLink" onClick={() => navigate("/login")}>
            Back to Login
          </Button>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
