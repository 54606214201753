import { ENDPOINTS } from "@/api/application-api";
import { ToastType } from "@/enums";
import { displayToast } from "@/utils/toast";
import { QueryClient } from "@tanstack/react-query";
import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  transaction,
} from "mobx";
import { v4 } from "uuid";
import {
  EdgeCondition,
  EdgeStatusType,
  NodeOutput,
  ObservableWorkflowNode,
  SaveWorkflowMutationResponse,
  Workflow,
  WorkflowEdge,
  WorkflowNode,
  WorkflowNodeStatus,
  WorkflowNodeType,
} from "../types";
import {
  nodeHasSource,
  withIdToNameRemapping,
  withIdToNameRemappingForOneNode,
  withNameToIdRemapping,
} from "../utils";
import { ContextMenuType } from "./Canvas/ContextMenu";
import {
  createAddWorkflowLabelMutation,
  createDeleteWorkflowEdgeMutation,
  createDeleteWorkflowLabelMutation,
  createDeleteWorkflowMutation,
  createExecuteWorkflowMutation,
  createPlayFromHereMutation,
  createSaveMutation,
  createSaveSingleNodeMutation,
  createUpdateWorkflowLabelMutation,
  createUpdateWorkflowNameMutation,
  createWorkflowEdgeMutation,
} from "./workflow-queries";

interface WorkflowStoreProps extends Workflow {
  readonly?: boolean;
  edges?: WorkflowEdge[];
  userToken: string | null;
}

interface ContextMenuProps {
  id: string;
  top: number;
  left: number;
  type: ContextMenuType;
}

class WorkflowStore implements Workflow {
  readonly: boolean;
  isConnecting = false;
  activeNodeName?: string;
  activeNodeId?: string;
  workflow_id: number | null = -1;
  workspace_id: number = -1;
  id: number = -1;
  @observable name: string = "";
  commit_message: string = "";
  nodes = observable.array<ObservableWorkflowNode>();
  @observable.deep edges: WorkflowEdge[] = [];
  input_variables: Record<string, string> = {};
  queryClient = new QueryClient();
  version_id?: number;
  is_draft: boolean = false;
  // UI
  isSidebarOpen = false;
  isStarted = false;
  contextMenu: ContextMenuProps | null = null;
  userToken: string | null;
  private initial_payload: string | null = null;

  errors: SaveWorkflowMutationResponse["message"] | null = null;
  [key: string]: any;

  constructor(workflow: WorkflowStoreProps) {
    this.workflow_id = workflow.workflow_id;
    this.workspace_id = workflow.workspace_id;
    this.name = workflow.name;
    this.commit_message = workflow.commit_message;
    this.nodes = observable.array(withNameToIdRemapping(workflow.nodes));

    runInAction(() => {
      this.edges = withNameToIdRemapping(workflow.nodes).flatMap((node) =>
        node.dependencies.map((targetId) => {
          const sourceName = node.name;
          const targetName = this.convertNodeIdToName(targetId);

          const edgeMatch: WorkflowEdge | false =
            workflow.edges?.find(
              (edge) =>
                edge.source_node_name === targetName &&
                edge.target_node_name === sourceName,
            ) || false;

          return {
            id: (edgeMatch && edgeMatch.id) || v4(),
            is_and: true,
            workflow_version_id: workflow.version_id || 0,
            ...edgeMatch,
            conditionals: observable.array(
              (edgeMatch && edgeMatch.conditionals) || [],
            ),
            source_node_name: targetId,
            target_node_name: node.id,
          };
        }),
      );
    });

    this.input_variables = workflow.input_variables;
    this.readonly = !!workflow.readonly;
    this.userToken = workflow.userToken;
    this.version_id = workflow.version_id!;
    this.saveMutation = createSaveMutation(this.userToken!, this.queryClient);
    this.executeWorkflowMutation = createExecuteWorkflowMutation(
      this.userToken!,
      this.queryClient,
    );
    this.saveSingleNodeMutation = createSaveSingleNodeMutation(
      this.userToken!,
      this.queryClient,
    );
    this.deleteMutation = createDeleteWorkflowMutation(
      this.userToken!,
      this.queryClient,
    );

    this.playFromHereMutation = createPlayFromHereMutation(
      this.userToken!,
      this.queryClient,
    );

    this.addReleaseLabelMutation = createAddWorkflowLabelMutation(
      this.userToken!,
      this.queryClient,
    );

    this.removeReleaseLabelMutation = createDeleteWorkflowLabelMutation(
      this.userToken!,
      this.queryClient,
    );

    this.updateWorkflowNameMutation = createUpdateWorkflowNameMutation(
      this.userToken!,
      this.queryClient,
    );

    this.updateWorkflowLabelMutation = createUpdateWorkflowLabelMutation(
      this.userToken!,
      this.queryClient,
    );

    this.createWorkflowEdgeMutation = createWorkflowEdgeMutation(
      this.userToken!,
      this.workspace_id,
      this.queryClient,
    );

    this.createDeleteEdgeMutation = createDeleteWorkflowEdgeMutation(
      this.userToken!,
      this.workspace_id,
      this.queryClient,
    );

    this.disposeIsRunningReaction = reaction(
      () => this.isRunning,
      (isRunning, previousIsRunning) => {
        if (previousIsRunning && !isRunning) {
          this.resetTriggeredStates();
        }
      },
    );
    makeAutoObservable(this, {
      edges: observable.deep,
    });

    this.initial_payload = JSON.stringify(this.toSchema());
  }

  // Workflow Management Methods
  // ---------------------------

  printDebug() {
    console.log("Workflow Debug Information:");
    console.log("---------------------------");
    console.log(`Workflow ID: ${this.workflow_id}`);
    console.log(`Workspace ID: ${this.workspace_id}`);
    console.log(`Name: ${this.name}`);
    console.log(`Commit Message: ${this.commit_message}`);
    console.log(`Is Draft: ${this.is_draft}`);
    console.log(`Active Node ID: ${this.activeNodeId}`);
    console.log(`Is Connecting: ${this.isConnecting}`);
    console.log(`Is Sidebar Open: ${this.isSidebarOpen}`);
    console.log(`Is Started: ${this.isStarted}`);
    console.log("\nNodes:");
    this.nodes.forEach((node, index) => {
      console.log(`\nNode ${index + 1}:`);
      console.log(`  ID: ${node.id}`);
      console.log(`  Name: ${node.name}`);
      console.log(`  Type: ${node.node_type}`);
      console.log(`  Status: ${node.status}`);
      console.log(`  Is Output Node: ${node.is_output_node}`);
      console.log(`  Dependencies: ${JSON.stringify(node.dependencies)}`);
      console.log(`  Errors: ${JSON.stringify(node.errors)}`);
      console.log(`  Triggered: ${node.triggered}`);
      console.log(
        `  Configuration: ${JSON.stringify(node.configuration, null, 2)}`,
      );
    });
    console.log("\nInput Variables:");
    console.log(JSON.stringify(this.input_variables, null, 2));
  }

  convertNodeIdToName(id: string) {
    const node = this.getNodeById(id);
    if (node) {
      return node.name;
    }
    return null;
  }

  get hasBeenEdited() {
    return this.initial_payload !== JSON.stringify(this.toSchema());
  }

  toSchema() {
    return {
      workflow_id: this.workflow_id === -1 ? null : this.workflow_id,
      workspace_id: this.workflow_id === -1 ? this.workspace_id : undefined,
      name: this.name,
      commit_message: this.commit_message,
      nodes: withIdToNameRemapping(this.nodes),
      edges: this.edges
        .filter(({ conditionals }) => conditionals.length > 0)
        .map((e) => ({
          source_node_name: this.convertNodeIdToName(e.source_node_name),
          target_node_name: this.convertNodeIdToName(e.target_node_name),
          is_and: e.is_and,
          conditionals: e.conditionals.map((c) => ({
            ...c,
            left_config: {
              type: "source" in c.left_config ? "source" : "static_value",
              ...c.left_config,
            },
            right_config: {
              type: "source" in c.right_config ? "source" : "static_value",
              ...c.right_config,
            },
          })),
        })),
      required_input_variables: this.input_variables,
      is_draft: this.is_draft,
    };
  }

  delete(callback?: () => void) {
    this.deleteMutation.mutate(
      { workflow_id: this.workflow_id! },
      {
        onSuccess: (data: any) => {
          if (data.success) {
            displayToast("Workflow deleted", ToastType.success);
            Promise.all([
              this.queryClient.invalidateQueries([ENDPOINTS.workflows]),
              this.queryClient.invalidateQueries([ENDPOINTS.workflow_versions]),
            ]).then(() => callback?.());
          } else
            this.handleError(
              data.error || data.message || data || "Unknown error",
              (reason) => {
                console.error("Error deleting workflow:", reason);
              },
            );
        },
        onError: (error: any) => {
          this.handleError(
            error.message || error.error || error || "Unknown error",
            (reason) => {
              console.error("Error deleting workflow:", reason);
            },
          );
        },
      },
    );
  }

  save(
    commitMessage: string,
    existing = false,
  ): Promise<SaveWorkflowMutationResponse> {
    this.errors = null;
    this.commit_message = commitMessage;
    return new Promise((resolve, reject) => {
      this.saveMutation.mutate(
        { is_existing: existing, workflowSchema: this.toSchema() },
        {
          onSuccess: (data: SaveWorkflowMutationResponse) => {
            if (data.success) {
              displayToast("Workflow saved", ToastType.success);
              this.initial_payload = JSON.stringify(this.toSchema());
              Promise.all([
                this.queryClient.invalidateQueries([ENDPOINTS.workflows]),
                this.queryClient.invalidateQueries([
                  ENDPOINTS.workflow_versions,
                ]),
              ]).then(() => resolve(data));
            } else
              this.handleError(
                data.error || data.message || data || "Unknown error",
                reject,
              );
          },
          onError: (error: any) => {
            this.handleError(
              error.error || error.message || error || "Unknown error",
              reject,
            );
          },
        },
      );
    });
  }

  set(workflow: Workflow) {
    this.workflow_id = workflow.workflow_id;
    this.workspace_id = workflow.workspace_id;
    this.name = workflow.name;
    this.commit_message = workflow.commit_message;
    this.nodes.replace(workflow.nodes);
    this.input_variables = workflow.input_variables;
    this.version_id = workflow.version_id!;
  }

  updateFromProps(workflow: WorkflowStoreProps) {
    runInAction(() => {
      this.workflow_id = workflow.workflow_id;
      this.workspace_id = workflow.workspace_id;
      this.name = workflow.name;
      this.commit_message = workflow.commit_message;
      this.nodes.replace(workflow.nodes);
      this.input_variables = workflow.input_variables;
      this.readonly = !!workflow.readonly;
    });
  }

  dispose() {
    this.saveMutation.dispose();
    if (this.disposeIsRunningReaction) {
      this.disposeIsRunningReaction();
    }
  }

  // Node Management Methods
  // -----------------------

  get activeNode(): ObservableWorkflowNode | undefined {
    return this.nodes.find((node) => node.id === this.activeNodeId);
  }
  @action
  addNode(node: WorkflowNode): ObservableWorkflowNode {
    const newNode = this.nodes[this.nodes.push(node) - 1];

    // Add edges from dependencies
    node.dependencies.forEach((dependencyId) => {
      this.addEdge({
        id: v4(),
        conditionals: [],
        workflow_version_id: this.version_id!,
        source_node_name: dependencyId,
        target_node_name: node.id,
        is_and: true,
      });
    });

    return newNode;
  }

  @action
  removeNode(id: string) {
    const updatedNodes = this.nodes.filter((node) => node.id !== id);
    updatedNodes.forEach((node) => {
      const configuration = node.configuration;
      if ("prompt_template_variable_mappings" in configuration) {
        const variablesToRemove = Object.entries(
          configuration.prompt_template_variable_mappings!,
        ).filter(([_, value]) => value === id);
        variablesToRemove.forEach(([key, value]) => {
          delete configuration.prompt_template_variable_mappings![key];
        });
      }
      if ("sources" in configuration)
        configuration.sources = configuration.sources?.filter(
          (source) => source !== id,
        );

      if ("source" in configuration)
        configuration.source =
          configuration.source === id ? undefined : configuration.source;

      if ("first_source" in configuration)
        configuration.first_source =
          configuration.first_source === id
            ? undefined
            : configuration.first_source;

      if ("second_source" in configuration)
        configuration.second_source =
          configuration.second_source === id
            ? undefined
            : configuration.second_source;

      node.dependencies = node.dependencies.filter((dep) => dep !== id);
    });

    const toBeDeleted = this.edges.filter(
      (edge) =>
        (edge.source_node_name === id || edge.target_node_name === id) &&
        edge.conditionals.length,
    );

    toBeDeleted.forEach((edge) => {
      this.removeEdge(edge.id);
    });

    this.nodes.replace(updatedNodes);
  }

  @action
  updateNode(name: string, updateFn: (node: WorkflowNode) => WorkflowNode) {
    const index = this.nodes.findIndex((node) => node.name === name);
    if (index !== -1) {
      const updatedNode = updateFn(this.nodes[index]);
      this.nodes.splice(index, 1, updatedNode);
    }
  }

  @action
  replaceNode(name: string, replacementNode: Omit<WorkflowNode, "id">) {
    runInAction(() => {
      const index = this.nodes.findIndex((node) => node.name === name);
      if (index !== -1) {
        const currentNode = this.nodes[index];
        const hasChanged = Object.keys(replacementNode).some(
          (key) =>
            JSON.stringify((currentNode as any)[key]) !==
            JSON.stringify((replacementNode as any)[key]),
        );
        const updatedNode = {
          ...currentNode,
          ...replacementNode,
          saved: hasChanged ? false : currentNode.saved,
        };

        const templateName = replacementNode.configuration?.template?.name;
        if (templateName && currentNode.has_default_name) {
          const existingNodes = this.nodes.filter(
            (n) => n.name.startsWith(templateName) && n.id !== currentNode.id,
          );

          if (existingNodes.length > 0) {
            const numbers = existingNodes
              .map((n) => {
                const match = n.name.match(
                  new RegExp(`^${templateName}\\s*(\\d+)?$`),
                );
                return match ? parseInt(match[1]) || 1 : 0;
              })
              .filter((n) => !isNaN(n));

            const highestNumber = Math.max(0, ...numbers);
            updatedNode.name = `${templateName} ${highestNumber + 1}`;
          } else {
            updatedNode.name = templateName;
          }
          updatedNode.has_default_name = false;
        }

        this.nodes.splice(index, 1, updatedNode);
      }
    });
  }

  @action
  addOutput(nodeId: string, output: NodeOutput) {
    const node = this.getNodeById(nodeId);
    if (node) {
      node.outputs = node.outputs || [];
      node.outputs.push(output);
    }
  }

  setOutputNode(id: string, is_output_node = true) {
    const updatedNodes = this.nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          is_output_node,
        };
      } else {
        return {
          ...node,
          is_output_node: false,
        };
      }
    });
    this.nodes.replace(updatedNodes);
  }

  addDependency(nodeId: string, dependencyId: string) {
    const associatedNode = this.getNodeById(nodeId);
    if (associatedNode) {
      associatedNode.dependencies = [
        ...associatedNode.dependencies,
        dependencyId,
      ];
    }
    const id = v4();

    this.addEdge({
      id,
      conditionals: [],
      workflow_version_id: this.version_id!,
      source_node_name: dependencyId,
      target_node_name: nodeId,
      is_and: true,
    });

    this.saveNode(associatedNode!);
    return id;
  }

  @action
  setNodeStatus(workflowNodeId: string, status: WorkflowNodeStatus) {
    const node = this.getNodeById(workflowNodeId);
    if (node) node.status = status;
  }

  getNodeName(workflowNodeId: string) {
    const node = this.getNodeById(workflowNodeId);
    if (node) return node.name;
    return "";
  }

  // Edge Methods
  // ------------
  @action addCondition(edgeId: string, condition: EdgeCondition) {
    const edge = this.edges.find((edge) => edge.id === edgeId);
    if (edge) {
      edge.conditionals = [...edge.conditionals, condition];
    }
  } // add reaction to auto-save edges as they're updated

  @action setCondition(
    edgeId: string,
    updatedCondition: Partial<EdgeCondition>,
  ) {
    const edge = this.edges.find((edge) => edge.id === edgeId);
    if (!edge) return;

    const conditionIndex = edge.conditionals.findIndex(
      (condition) => condition.position === updatedCondition.position,
    );

    if (conditionIndex !== -1) {
      edge.conditionals[conditionIndex] = {
        ...edge.conditionals[conditionIndex],
        ...updatedCondition,
      };
    }
  }

  @action toggleEdgeIsAnd(edgeId: string) {
    const edge = this.edges.find((edge) => edge.id === edgeId);
    if (edge) {
      edge.is_and = !edge.is_and;
    }
  }

  @action clearConditions(edgeId: string) {
    const edge = this.edges.find((edge) => edge.id === edgeId);
    if (edge) {
      edge.conditionals = [];
    }
  }

  @action removeCondition(edgeId: string, conditionId: string) {
    const edge = this.edges.find((edge) => edge.id === edgeId);
    if (edge) {
      edge.conditionals = edge.conditionals.filter(
        (cond) => cond.id !== conditionId,
      );
    }
  }

  getEdge(edgeId: string) {
    return this.edges.find((edge) => edge.id === edgeId);
  }

  @action addEdge(edge: WorkflowEdge) {
    runInAction(() => {
      this.edges.push(edge);
    });
  }

  @action removeEdge(edgeId: string) {
    runInAction(() => {
      const edge = this.edges.find((edge) => edge.id === edgeId);
      if (edge?.conditionals.length) {
        this.createDeleteEdgeMutation.mutate(
          { edge_id: edgeId },
          {
            onSuccess: (res: any) => {
              if (!res.success) {
                this.handleError(
                  res.error || res.message || res || "Unknown error",
                  (reason) => {
                    console.error("Error deleting workflow edge:", reason);
                  },
                );
              }
            },
            onError: (error: any) => {
              this.handleError(
                error.message || error.error || error || "Unknown error",
                (reason) => {
                  console.error("Error deleting workflow edge:", reason);
                },
              );
            },
          },
        );
      }
      const edgeSource = edge?.source_node_name;
      const edgeTarget = edge?.target_node_name;
      const targetNode = this.getNodeById(edgeTarget!);
      if (targetNode) {
        targetNode.dependencies = targetNode.dependencies.filter(
          (dep) => dep !== edgeSource,
        );
        this.saveNode(targetNode!);
      }
      this.edges = this.edges.filter((edge) => edge.id !== edgeId);
    });
  }

  getEdgeOriginNode(edgeId: string) {
    return withNameToIdRemapping(this.nodes).find(
      (node) => edgeId in node.dependencies,
    );
  }

  // Node Query Methods
  // ------------------

  getNodeByName(name: string): ObservableWorkflowNode | undefined {
    const node = this.nodes?.find((node) => node.name === name);
    return node;
  }

  getNodeById(workflowNodeId: string) {
    return this.nodes.find((node) => node.id === workflowNodeId);
  }

  indexOf(nodeId: string) {
    return this.nodes.findIndex((node) => node.id === nodeId);
  }

  isOutputNode(id: string) {
    return this.nodes.find((node) => node.id === id)?.is_output_node;
  }

  // Input Variable Management Methods
  // ---------------------------------

  addInputVariable(key: string, value: string) {
    this.input_variables[key] = value;
  }

  updateInputVariable(key: string, field: "key" | "value", newValue: string) {
    if (field === "key") {
      const value = this.input_variables[key];
      delete this.input_variables[key];
      this.input_variables[newValue] = value;
    } else {
      this.input_variables[key] = newValue;
    }
  }

  removeInputVariable(key: string) {
    delete this.input_variables[key];
  }

  updateInputVariables(inputVariables: Record<string, string>) {
    this.input_variables = inputVariables;
  }

  // Error Handling Methods
  // ----------------------

  @action
  setErrors(errors: SaveWorkflowMutationResponse["message"] | null) {
    transaction(() => {
      if (errors === null) {
        this.errors = null;
        this.nodes.forEach((node) => {
          node.errors = [];
        });
      } else {
        this.errors = errors;
        const nodeErrors =
          (typeof errors !== "string" &&
            errors?.filter((error) => error.loc.includes("nodes"))) ||
          [];

        this.nodes.forEach((node) => {
          const existingErrors = node.errors || [];
          const newErrors = nodeErrors.filter((error) => {
            const errorIndex = Number(error.loc[1]);
            return (
              errorIndex ===
              this.nodes.findIndex(({ name }) => node.name === name)
            );
          });
          node.errors = [...existingErrors, ...newErrors];
        });
      }
    });
  }

  private handleError(errorMessage: any, reject: (reason?: any) => void) {
    if (typeof errorMessage === "string") {
      errorMessage = [
        {
          loc: [],
          msg: errorMessage,
          type: "error",
        },
      ];
    }
    runInAction(() => this.setErrors(errorMessage));
    reject({ success: false, message: errorMessage });
  }

  getNodeErrors(nodeName: string) {
    return this.nodeErrors.filter((error) => {
      const errorIndex = Number(error.loc[1]);
      return (
        errorIndex === this.nodes.findIndex((node) => node.name === nodeName)
      );
    });
  }

  get generalErrors() {
    if (!this.errors) return [];
    if (typeof this.errors === "string") return this.errors;
    return this.errors.filter(
      (error) =>
        !error.loc.includes("nodes") ||
        (error.loc.includes("nodes") && error.loc.length === 1),
    );
  }

  get nextNodeLabel() {
    const prefix = "Node";
    let counter = 1;
    const usedLabels = new Set(this.nodes.map((node) => node.name));
    while (true) {
      const newLabel = `${prefix} ${counter}`;
      if (!usedLabels.has(newLabel)) {
        return newLabel;
      }
      counter++;
    }
  }

  // UI Methods
  // ----------

  @action
  setName(name: string, save = false) {
    const originalName = this.name;
    runInAction(() => (this.name = name));
    if (save) {
      this.updateWorkflowNameMutation.mutate(
        {
          id: this.workflow_id!,
          name,
        },
        {
          onSuccess: (res: any) => {
            if (!res.success) {
              runInAction(() => {
                this.name = originalName; // Revert the name change
              });
              this.handleError(
                res.error || res.message || res || "Unknown error",
                (reason) => {
                  console.error("Error updating workflow name:", reason);
                },
              );
            }
          },
          onError: (error: any) => {
            runInAction(() => {
              this.name = originalName; // Revert the name change
            });
            this.handleError(
              error.message || error.error || error || "Unknown error",
              (reason) => {
                console.error("Error updating workflow name:", reason);
              },
            );
          },
        },
      );
    }
  }

  isNodeSelected(id: string): boolean {
    return this.activeNodeId === id;
  }

  setIsConnecting(isConnecting: boolean) {
    this.isConnecting = isConnecting;
  }

  @action
  setActiveNode(node: ObservableWorkflowNode | undefined) {
    this.activeNodeId = node?.id;
  }

  @action
  removeActiveNode() {
    this.activeNodeId = undefined;
  }

  openSidebar() {
    this.isSidebarOpen = true;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  @action
  setSidebarOpen(isSidebarOpen: boolean) {
    this.isSidebarOpen = isSidebarOpen;
  }

  @action
  setContextMenu(contextMenu: ContextMenuProps | null) {
    this.contextMenu = contextMenu;
  }

  isEdgeSelected(id: string): boolean {
    return this.contextMenu?.id === id && this.contextMenu?.type === "edge";
  }

  getEdgeConditionsCount(id: string): number {
    const edge = this.getEdge(id)?.conditionals;
    return edge?.length || 0;
  }

  // Execution Methods
  // -----------------

  @action
  resetNodeStatuses() {
    this.nodes.forEach((node) => {
      node.status = undefined;
      node.errors = [];
    });
    this.edges.forEach((edge) => {
      edge.status = undefined;
    });
  }

  @action
  resetTriggeredStates() {
    this.nodes.forEach((node) => {
      node.triggered = false;
    });
    this.isStarted = false;
  }

  @action
  run = () => {
    if (this.isStarted) return;
    this.resetNodeStatuses();
    this.isStarted = true;
    this.executeWorkflowMutation.mutate(
      {
        workflow_version_id: this.version_id!,
        input_variables: this.input_variables,
        workflow_id: this.workflow_id,
      },
      {
        onSuccess: () =>
          runInAction(() => {
            this.isStarted = false;
          }),
        onError: () =>
          runInAction(() => {
            this.isStarted = false;
          }),
      },
    );
  };

  @action
  playFromHere = (node: ObservableWorkflowNode) => {
    runInAction(() => {
      node.triggered = true;
      node.errors = [];
      this.isStarted = true;
    });
    this.playFromHereMutation.mutate(
      {
        workflow_node_id: node.id!,
        workflow_id: this.workflow_id,
      },
      {
        onSuccess: (data: any) => {
          if (!data.success) {
            runInAction(() => {
              node.triggered = false;
              this.isStarted = false;
            });
            this.handleError(
              data.error || data.message || data || "Unknown error",
              (reason) => {
                console.error("Play from here failed:", reason);
              },
            );
          }
        },
        onError: (error: any) => {
          runInAction(() => {
            node.triggered = false;
            this.isStarted = false;
          });
          this.handleError(
            error.message || error.error || error || "Unknown error",
            (reason) => {
              console.error("Play from here failed:", reason);
            },
          );
        },
      },
    );
  };

  // Release Labels
  // --------------

  addReleaseLabel(name: string, versionId: number) {
    return new Promise((resolve, reject) => {
      this.addReleaseLabelMutation.mutate(
        {
          name,
          workflow_id: this.workflow_id!,
          workflow_version_id: versionId!,
        },
        {
          onSuccess: (data: any) => {
            this.queryClient.invalidateQueries([
              ENDPOINTS.workflow_versions,
              this.workflow_id,
            ]);

            if (!data.success && data.existing_workflow_label_id) {
              this.updateWorkflowLabelMutation.mutate(
                {
                  workflow_version_id: versionId!,
                  id: data.existing_workflow_label_id,
                },
                {
                  onSuccess: (data: any) => {
                    this.queryClient.invalidateQueries([
                      ENDPOINTS.workflow_versions,
                      this.workflow_id,
                    ]);
                    resolve(data);
                  },
                },
              );
            } else if (data.success) {
              resolve(data);
            } else {
              reject(data);
            }
          },
          onError: (error: any) => {
            reject(error);
          },
        },
      );
    });
  }

  removeReleaseLabel(labelId: number) {
    return new Promise((resolve, reject) => {
      this.removeReleaseLabelMutation.mutate(
        {
          id: labelId,
        },
        {
          onSuccess: (data: any) => {
            this.queryClient.invalidateQueries([
              ENDPOINTS.workflow_versions,
              this.workflow_id,
            ]);
            resolve(data);
          },
          onError: (error: any) => {
            reject(error);
          },
        },
      );
    });
  }

  // Utility Methods
  // ---------------
  async updateEdgeData(edge: WorkflowEdge) {
    return new Promise((resolve, reject) => {
      this.createWorkflowEdgeMutation.mutate(
        {
          id: edge.id,
          workflow_version_id: this.version_id!,
          source_node_name: this.getNodeName(edge.source_node_name),
          target_node_name: this.getNodeName(edge.target_node_name),
          is_and: edge.is_and,
          conditionals: edge.conditionals.map((condition) => ({
            position: condition.position,
            operator: condition.operator,
            left_config: {
              type:
                "source" in condition.left_config ? "source" : "static_value",
              ...condition.left_config,
            },
            right_config: {
              type:
                "source" in condition.right_config ? "source" : "static_value",
              ...condition.right_config,
            },
          })),
        },
        {
          onSuccess: (res: any) => {
            if (!res.success) {
              this.handleError(
                res.error || res.message || res || "Unknown error",
                (reason) => {
                  console.error("Error creating workflow edge:", reason);
                },
              );
              reject(false);
            } else {
              resolve(true);
            }
          },
          onError: (error: any) => {
            this.handleError(
              error.message || error.error || error || "Unknown error",
              (reason) => {
                console.error("Error creating workflow edge:", reason);
              },
            );
            reject(false);
          },
        },
      );
    });
  }

  @action setEdgeStatus(edgeId: string, status: EdgeStatusType) {
    const edge = this.edges.find((edge) => edge.id === edgeId);
    if (edge) {
      runInAction(() => {
        edge.status = status;
      });
    }
  }

  async updateEdge(edge: WorkflowEdge) {
    const edgeReference = this.edges.find(({ id }) => id === edge.id);
    const MINIMUM_LOADING_TIME = 750; // 750ms minimum loading time

    const endLoad = () => {
      runInAction(() => {
        if (edgeReference) {
          edgeReference.is_loading = false;
        }
      });
    };

    runInAction(() => {
      if (edgeReference) {
        if (edgeReference?.status) delete edgeReference.status; // Reset status
        edgeReference.is_loading = true;
      }
    });

    const startTime = Date.now();
    await this.updateEdgeData(edgeReference!);
    const elapsed = Date.now() - startTime;

    if (elapsed < MINIMUM_LOADING_TIME) {
      await new Promise((resolve) =>
        setTimeout(resolve, MINIMUM_LOADING_TIME - elapsed),
      );
    }

    endLoad();
  }

  async saveNode(node: WorkflowNode, nameChanged = false): Promise<boolean> {
    const nodesToSave = [node];
    if (nameChanged) {
      const dependentNodes = this.nodes.filter(
        (n) => n.dependencies.includes(node.id) || nodeHasSource(n, node.id),
      );
      nodesToSave.push(...dependentNodes);
    }
    const results = await Promise.all(
      nodesToSave.map((n) => {
        return new Promise((resolve, reject) => {
          // Convert node ids in dependencies to node names
          const nodeWithNameDependencies = withIdToNameRemappingForOneNode(
            n,
            this.nodes,
          );
          this.saveSingleNodeMutation.mutateAsync(
            [nodeWithNameDependencies][0],
            {
              onSuccess: (res: any) => {
                if (!res.success) {
                  const nodeIndex = this.indexOf(node.id);
                  this.setErrors(
                    res.message.map((e: any) => {
                      console.log(e);
                      if (e.type === "missing") {
                        e.msg = `Field required: '${e.loc[e.loc.length - 1]}'`;
                      }

                      return {
                        ...e,
                        loc: ["nodes", nodeIndex, ...e.loc],
                      };
                    }),
                  );
                  resolve(false);
                } else {
                  const associatedNode = this.getNodeById(node.id);
                  runInAction(() => {
                    if (associatedNode) {
                      associatedNode.errors = [];
                      associatedNode.saved = true;
                    }
                  });
                  resolve(true);
                }
              },
              onError: (error: any) => {
                console.log("setting error to ", error);
                this.setErrors(error || error.message || error.error);
                reject(false);
              },
            },
          );
        });
      }),
    );

    return results.every((result) => result === true);
  }

  // Computed Properties
  // -------------------

  get allNodesCompleted() {
    return this.nodes.every(
      (node) =>
        !node?.status ||
        node.status === WorkflowNodeStatus.COMPLETED ||
        node.status === WorkflowNodeStatus.FAILED,
    );
  }

  get activeNodeType(): WorkflowNodeType | undefined {
    return this.activeNode?.node_type;
  }

  get nodeErrors() {
    if (typeof this.errors === "string" || !this.errors) return [];
    return this.errors.filter((error) => error.loc.includes("nodes"));
  }

  get nodeCount() {
    return this.nodes.length;
  }

  get rootNodes() {
    return this.nodes.filter((node) => node.dependencies.length === 0);
  }

  get isRunning() {
    return this.nodes.some(
      (node) =>
        node.status === WorkflowNodeStatus.RUNNING ||
        node.status === WorkflowNodeStatus.QUEUED,
    );
  }
}

type IWorkflow = WorkflowStore & Workflow;

export { IWorkflow, WorkflowStore, WorkflowStoreProps };
