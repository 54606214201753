import { LabelsSelect } from "@/components/LabelsSelect";
import { useLabels } from "@/components/LabelsSelect/hooks";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateABtest } from "@/queries";
import { PromptVersion } from "@/types/apiGetters";
import { displayErrorToast } from "@/utils/toast";
import { useNavigate } from "react-router-dom";
import useNavigateToPromptVersion from "../../hooks/useNavigateToPromptVersion";
import ABTestButton from "./ABTestButton";

interface ABTestProps {
  promptVersion: PromptVersion;
}

const ABTest = ({ promptVersion }: ABTestProps) => {
  const userToken = useAuth()?.userToken;
  const createABtest = useCreateABtest(userToken!);
  const isLoading = createABtest.isLoading;
  const navigate = useNavigate();
  const navigateToPromptVersion = useNavigateToPromptVersion(promptVersion);

  const { data: labels } = useLabels({
    promptId: promptVersion.prompt_id,
  });

  const user = useUser();
  const workspaceId = user?.activeWorkspaceId!;

  const handleClick = () => {
    createABtest.mutate(
      {
        promptId: promptVersion.prompt_id,
        promptVersionId: promptVersion.id,
        workspaceId,
      },
      {
        onSuccess: (res) => {
          if (res?.success && res?.release_label_group?.id) {
            navigate(
              `/workspace/${workspaceId}/ab-releases/prompt-release-group/${res?.release_label_group?.id}?prompt=${promptVersion.prompt_id}`,
            );
          } else {
            displayErrorToast(
              res?.message ?? "There's a problem with the AB Test request",
            );
          }
        },
        onError: () => {
          navigateToPromptVersion();
          displayErrorToast("There's a problem with the AB Test request");
        },
      },
    );
  };

  if (labels.length === 0) {
    return (
      <ABTestButton
        disabled={isLoading}
        isLoading={isLoading}
        onClick={handleClick}
      />
    );
  }

  return (
    <LabelsSelect
      promptVersion={{
        prompt_id: promptVersion.prompt_id,
        version_id: promptVersion.id,
      }}
      shouldNavigate
      labels={labels}
      isLoading={isLoading}
      trigger={<ABTestButton disabled={isLoading} />}
    />
  );
};

export default ABTest;
