import { Button } from "@/components/ui/button";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import { useSchemaEditor } from "..";
import ArrayTypeSwitch from "./ArrayTypeSwitch";
import Enums from "./Enums";
import ParameterContent from "./ParameterContent";
import ParameterMetadata from "./ParametersMetadata";
import { ISchemaParameter } from "./schema-parameter-store";

interface SchemaParameterProps {
  parameter: ISchemaParameter;
}

const SchemaParameter = observer(({ parameter }: SchemaParameterProps) => {
  const schemaEditor = useSchemaEditor();
  const isReadonly = schemaEditor.isReadonly;

  const indentStyle = {
    marginLeft: `${parameter.depth * 6}px`,
    position: "relative" as const,
  };

  const buttonIndentStyle = {
    marginLeft: `${(parameter.depth + 1) * 6}px`,
    position: "relative" as const,
  };

  const handleToggleCollapsed = (e: React.MouseEvent) => {
    e.stopPropagation();
    parameter.toggleCollapsed();
  };

  return (
    <div
      className={`relative flex min-w-[450px] flex-col gap-y-2  ${
        parameter.isChild ? " pl-4" : ""
      }`}
      style={{
        ...indentStyle,
      }}
    >
      <div
        className={`flex cursor-pointer select-none flex-row items-center justify-between gap-x-2 border-b   ${
          parameter.isParent ? "px-2 py-2" : ""
        } transition-colors hover:bg-gray-50`}
        onClick={handleToggleCollapsed}
      >
        <div className="flex items-center">
          <button
            onClick={handleToggleCollapsed}
            className="mr-2 rounded p-1 focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            {parameter.isExpanded ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
          <span
            className={`cursor-pointer text-sm text-gray-700 ${
              parameter.isParent ? "text-sm" : "text-xs"
            }`}
            onClick={handleToggleCollapsed}
          >
            {parameter.name ||
              (parameter.isParent ? `Untitled Parameter` : `Untitled Field`)}
          </span>
        </div>
        {!isReadonly && (
          <Button
            variant="destructiveLink"
            size="tinyIcon"
            onClick={(e) => {
              e.stopPropagation();
              schemaEditor.deleteParameter(parameter);
            }}
            className="focus:outline-none focus:ring-2 focus:ring-red-300"
          >
            <XIcon className="h-4 w-4" />
          </Button>
        )}
      </div>
      {parameter.isExpanded && (
        <div className="ml-3 flex flex-col gap-y-1">
          <ParameterMetadata
            {...parameter.metadata}
            onChangeName={(value) => !isReadonly && parameter.setName(value)}
            onChangeType={(type) => !isReadonly && parameter.setType(type)}
            onChangeTitle={(value) => !isReadonly && parameter.setTitle(value)}
            onChangeRequired={() => !isReadonly && parameter.toggleRequired()}
          />

          <ParameterContent
            content={parameter.content}
            onChangeContent={(value) => {
              !isReadonly && parameter.setContent(value);
            }}
            before={
              parameter.type === "array" && (
                <ArrayTypeSwitch
                  checked={parameter.isCompositionArray}
                  onChange={function (value: boolean): void {
                    !isReadonly && parameter.toggleArrayType();
                  }}
                  disabled={isReadonly}
                />
              )
            }
          >
            {parameter.enumsAvailable && (
              <Enums
                enums={parameter._enum}
                includeEnum={parameter.hasEnums}
                addEnum={() => !isReadonly && parameter.addEnum()}
                setEnum={(k, v) => !isReadonly && parameter.setEnum(k, v)}
                toggleEnums={() => !isReadonly && parameter.toggleEnums()}
                deleteEnum={(k) => !isReadonly && parameter.deleteEnum(k)}
              />
            )}
            {parameter.isEditableList && !isReadonly ? (
              <div className="mb-4 w-full  pl-4">
                <Button
                  onClick={() => {
                    parameter.addField();
                  }}
                  variant={"outline"}
                  style={buttonIndentStyle}
                  size={"sm"}
                >
                  <PlusIcon className="mr-1 h-3 w-3" /> Add Field
                </Button>
              </div>
            ) : null}
          </ParameterContent>
        </div>
      )}
    </div>
  );
});

export default SchemaParameter;
