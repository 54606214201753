import folderGraphic from "@/assets/folder.svg";
import DropdownMenu from "@/components/DropdownMenu";
import { FolderModal } from "@/components/CreateFolderModel";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useDeleteFolder } from "@/queries";
import { Folder } from "@/types/folders";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { MouseEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../DeleteConfirmationModal";

const FolderGrid = ({ folders }: { folders: Folder[] }) => {
  const [isDeleteFolderModalOpen, setDeleteFolderModalOpen] = useState(false);
  const [isRenameFolderModalOpen, setRenameFolderModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const authContext = useAuth();
  const navigate = useNavigate();
  const userContext = useUser();

  const folderList = useMemo((): Folder[] => {
    return [...folders].sort((a, b) => a.name.localeCompare(b.name));
  }, [folders]);

  const handleDeleteClick = (folder: Folder, e: MouseEvent) => {
    e.stopPropagation();
    setDeleteFolderModalOpen(true);
    setSelectedFolder(folder);
  };

  const handleRenameClick = (folder: Folder, e: MouseEvent) => {
    e.stopPropagation();
    setRenameFolderModalOpen(true);
    setSelectedFolder(folder);
  };
  const deleteFolder = useDeleteFolder(authContext!.userToken!);
  const handleDelete = async () => {
    try {
      const res = await deleteFolder.mutateAsync(selectedFolder?.id!);
      if (res?.success === false) {
        displayErrorToast(res?.message || "Failed to delete folder");
      } else {
        displayToast("Folder deleted", ToastType.success);
      }
      setDeleteFolderModalOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const renderFolders = () => {
    return folderList.map((folder) => (
      <li
        className="group flex items-center justify-between rounded-md border-2 border-gray-100 bg-white pr-4 shadow-sm hover:border-blue-100"
        key={folder.id}
      >
        <div className="w-full py-3 pl-4">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex-1 overflow-hidden">
              <div
                className="group flex cursor-pointer items-center truncate font-medium group-hover:text-blue-500"
                onClick={() =>
                  navigate(
                    `/workspace/${userContext?.activeWorkspaceId}/prompt-folder/${folder.id}`,
                  )
                }
              >
                <img
                  src={folderGraphic}
                  alt="Folder graphic"
                  className="mr-2 inline h-4 w-auto"
                />
                <span className="truncate">{folder.name}</span>
              </div>
            </div>
            <DropdownMenu
              iconSize="5"
              menuItems={[
                {
                  text: "Rename",
                  onClick: (e: any) => handleRenameClick(folder, e),
                },
                {
                  text: "Delete",
                  onClick: (e: any) => handleDeleteClick(folder, e),
                },
              ]}
            />
          </div>
        </div>
      </li>
    ));
  };

  return (
    <>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {renderFolders()}
      </ul>
      {isDeleteFolderModalOpen && selectedFolder && (
        <DeleteConfirmationModal
          title="Are you sure you want to delete this folder?"
          description="Deleting this folder will move its contained prompt templates to home. This action cannot be undone."
          onDelete={handleDelete}
          open={isDeleteFolderModalOpen}
          setOpen={setDeleteFolderModalOpen}
        />
      )}
      {isRenameFolderModalOpen && selectedFolder && (
        <FolderModal
          folder={selectedFolder}
          setOpen={setRenameFolderModalOpen}
        />
      )}
    </>
  );
};

export default FolderGrid;
