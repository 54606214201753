import { cn } from "@/lib/utils";

import { FolderBreadcrumbsProps } from "./types";
import { ChevronRightIcon, HomeIcon } from "@radix-ui/react-icons";
import { BasicTooltip } from "@/components/Tooltip";
import { OverflowDisplayTooltip } from "@/components/Tooltip/OverflowDisplayTooltip";
import { useFolderBreadcrumbs } from "./hooks/useFolderBreadcrumbs";

export const FolderBreadcrumbs = ({
  folderList,
  breadcrumbs,
  setBreadcrumbs,
  selectedFolderId,
  setCurrentFolder,
  setSelectedFolderId,
}: FolderBreadcrumbsProps) => {
  const {
    handleSelectBreadcrumb,
    goHome,
    isOverLimit,
    breadcrumbsToRender,
    overLimitBreadcrumb,
    isOnHome,
  } = useFolderBreadcrumbs({
    setSelectedFolderId,
    setCurrentFolder,
    setBreadcrumbs,
    breadcrumbs,
    folderList,
    selectedFolderId,
  });

  return (
    <div className="flex h-[20px] select-none items-center gap-1 px-2 text-sm text-muted-foreground">
      <BasicTooltip content="Home">
        <span onClick={goHome}>
          <HomeIcon
            className={cn(
              "h-4 w-auto cursor-pointer hover:text-primary/80 active:text-primary",
              isOnHome && "text-primary",
            )}
          />
        </span>
      </BasicTooltip>
      <ChevronRightIcon
        className={cn("h-4 w-auto", isOnHome && "text-primary")}
      />
      {isOverLimit && (
        <>
          <div
            onClick={() => handleSelectBreadcrumb(overLimitBreadcrumb.id)}
            className={
              "flex max-w-[120px] cursor-pointer items-center overflow-hidden hover:text-primary/80 active:text-primary"
            }
          >
            <BasicTooltip content={overLimitBreadcrumb.name}>
              <span>...</span>
            </BasicTooltip>
          </div>
          <ChevronRightIcon className={"h-4 w-auto"} />
        </>
      )}
      {breadcrumbsToRender.map((breadcrumb) => {
        const isNameTooLong = breadcrumb.name.length > 16;
        const isSelected = selectedFolderId === breadcrumb.id;

        return (
          <div
            className="flex items-center"
            key={`${breadcrumb.id}-${breadcrumb.name}-breadcrumb`}
          >
            <div
              onClick={() => handleSelectBreadcrumb(breadcrumb.id)}
              className={cn(
                "flex max-w-[120px] cursor-pointer items-center overflow-hidden hover:text-primary/80 active:text-primary",
                isNameTooLong && "max-w-[70px]",
                isSelected && "text-primary",
              )}
            >
              <OverflowDisplayTooltip
                className="text-mdr"
                content={breadcrumb.name}
              >
                {breadcrumb.name}
              </OverflowDisplayTooltip>
            </div>
            <ChevronRightIcon
              className={cn("h-4 w-auto", isSelected && "text-primary")}
            />
          </div>
        );
      })}
    </div>
  );
};
