import { makeAutoObservable, observable, runInAction } from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import React, { createContext, useContext } from "react";
import { v4 } from "uuid";
import { Filter, FilterType, filterTypeValues } from "./types";

class FilterStore {
  _filters = observable.map<string, Filter>();

  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

  removeFilter(id: string) {
    runInAction(() => this._filters.delete(id));
  }

  get filters() {
    return Array.from(this._filters.values());
  }

  get schema() {
    return JSON.stringify(this.filters);
  }

  get payload() {
    const metadataAndFilters = this.filters.filter(
      (filter) => filter.type === FilterType.METADATA_AND,
    );
    const metadataOrFilters = this.filters.filter(
      (filter) => filter.type === FilterType.METADATA_OR,
    );
    const dateRangeFilter = this.filters.find(
      (filter) => filter.type === FilterType.DATE_RANGE,
    );
    const scoreFilters = this.filters.filter(
      (filter) => filter.type === FilterType.SCORE,
    );
    const tagsAndFilter = this.filters.find(
      (filter) => filter.type === FilterType.TAGS_AND,
    );
    const tagsOrFilter = this.filters.find(
      (filter) => filter.type === FilterType.TAGS_OR,
    );

    return {
      start_time: dateRangeFilter ? dateRangeFilter.start : undefined,
      end_time: dateRangeFilter ? dateRangeFilter.end : undefined,
      metadata_and: metadataAndFilters.map((filter) => ({
        key: filter.property,
        value: Array.from(filter.values).join(","),
      })),
      metadata_or: metadataOrFilters.map((filter) => ({
        key: filter.property,
        value: Array.from(filter.values).join(","),
      })),
      scores: scoreFilters.map((filter) => ({
        name: filter.property,
        operator: filter.operator,
        value: filter.value!,
      })),
      tags_and: tagsAndFilter ? tagsAndFilter.tags : [],
      tags_or: tagsOrFilter ? tagsOrFilter.tags : [],
    };
  }

  updateFilter<T extends Filter>(
    id: string,
    updates: Partial<Omit<T, "id" | "type">>,
  ) {
    runInAction(() => {
      const filter = this._filters.get(id);
      if (filter) {
        this._filters.set(id, { ...filter, ...updates });
      }
    });
  }

  clearFilters() {
    runInAction(() => {
      this._filters.clear();
    });
  }

  get availableFilters() {
    const exceptions = [
      FilterType.METADATA_OR,
      FilterType.METADATA_AND,
      FilterType.SCORE,
    ];
    return filterTypeValues.filter(
      (value) =>
        exceptions.includes(value) ||
        !this.filters.map(({ type }) => type).includes(value),
    );
  }

  addFilter(filterType: FilterType) {
    const id = v4();
    switch (filterType) {
      case FilterType.METADATA_AND:
      case FilterType.METADATA_OR:
        this._filters.set(id, {
          id,
          type: filterType,
          property: "",
          values: observable.set<string>(),
        });
        break;
      case FilterType.SCORE:
        this._filters.set(id, {
          id,
          type: FilterType.SCORE,
          property: "",
          operator: "=",
        });
        break;
      case FilterType.DATE_RANGE:
        this._filters.set(id, {
          id,
          type: FilterType.DATE_RANGE,
          start: undefined,
          end: undefined,
        });
        break;
      case FilterType.TAGS_AND:
        this._filters.set(id, {
          id,
          type: FilterType.TAGS_AND,
          tags: observable.array(),
        });
        break;
      case FilterType.TAGS_OR:
        this._filters.set(id, {
          id,
          type: FilterType.TAGS_OR,
          tags: observable.array(),
        });
        break;
    }
  }
}

const FilterContext = createContext<FilterStore | null>(null);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const store = useLocalObservable(() => new FilterStore());
  return (
    <FilterContext.Provider value={store}>{children}</FilterContext.Provider>
  );
};

export const useFilterStore = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterStore must be used within a FilterProvider");
  }
  return context;
};

export const withFilterStore = <P extends {}>(
  Component: React.ComponentType<P>,
) => {
  return (props: P) => {
    return (
      <FilterProvider>
        <Component {...props} />
      </FilterProvider>
    );
  };
};

export type IFilterStore = InstanceType<typeof FilterStore>;

export default FilterStore;
