import { Tab } from "@headlessui/react";
import { ChatIcon, CheckIcon } from "@heroicons/react/outline";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { ExternalLinkIcon, PlusIcon, User, XIcon } from "lucide-react";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import ExperimentModal from "@/components/ExperimentModal";
import LoadingSpinner from "@/components/LoadingSpinner";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useIntersectionObserver } from "@/hooks";
import {
  useCreateABtest,
  useDynamicReleaseLabelsByPromptRegistry,
} from "@/queries";
import { promptLabel } from "@/schemas";
import { ListPromptVersion } from "@/types";
import { ReportScore } from "@/types/evaluate";
import { reportScoreForVersionList } from "@/utils/evaluate";
import { classNames } from "@/utils/strings";
import { InfiniteQueryObserverBaseResult } from "@tanstack/react-query";
import { LabelsSelect } from "../LabelsSelect";
import { useLabels } from "../LabelsSelect/hooks";
import { displayErrorToast } from "@/utils/toast";

type PromptTemplateVersionListProps = {
  handleDeleteVersionLabel: ({
    name,
    id,
    promptId,
    versionId,
    onSuccess,
  }: {
    name: string;
    id: number;
    promptId: number;
    versionId: number;
    onSuccess: () => void;
  }) => void;
  promptId: string;
  requestCountsData: { [key: string]: number };
  versionEvaluationScoresData: { [key: number]: ReportScore | null };
  requestsForPrompt: any;
  selectedVersionNumber: number;
  setOpenLabelForm: (value: boolean) => void;
  versionId?: string;
  promptTemplateVersions: ListPromptVersion[];
  setVersionToAddLabel: ({
    versionId,
    onSuccess,
  }: {
    versionId: number;
    onSuccess: () => void;
  }) => void;
} & InfiniteQueryObserverBaseResult;

const requestCountFormatter = Intl.NumberFormat("en-US", {
  notation: "compact",
});

export const PromptTemplateVersionList = ({
  handleDeleteVersionLabel,
  promptId,
  requestCountsData,
  versionEvaluationScoresData,
  selectedVersionNumber,
  setOpenLabelForm,
  setShowVersionList,
  versionId,
  promptTemplateVersions,
  isLoading,
  fetchNextPage,
  hasNextPage,
  setVersionToAddLabel,
}: PromptTemplateVersionListProps & {
  setShowVersionList: (value: boolean) => void;
}) => {
  const [isExperimentModalOpen, setExperimentModalOpen] = useState(false);
  const [isLabelsSelectOpen, setLabelsSelectOpen] = useState(false);
  const [selectedReleaseLabelGroupId, setSelectedReleaseLabelGroupId] =
    useState<number | null>(null);
  const [versionIdForABTest, setVersionIdForABTest] = useState<number | null>(
    null,
  );
  const authContext = useAuth();
  const userToken = authContext!.userToken!;
  const user = useUser();
  const { data, refetch: refetchReleaseLabels } =
    useDynamicReleaseLabelsByPromptRegistry(userToken, promptId);
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = entry?.isIntersecting;
  const navigate = useNavigate();
  const createABtest = useCreateABtest(userToken);
  const { data: allLabels, refetch: refetchLabels } = useLabels({
    promptId: Number(promptId),
  });

  useEffect(() => {
    if (isVisible) fetchNextPage();
  }, [isVisible, fetchNextPage]);

  const dynamicReleaseLabelsByPromptRegistry = useMemo(() => {
    if (!data?.success) return {};
    return data.dynamic_release_labels;
  }, [data?.dynamic_release_labels, data?.success]);

  const handleDynamicReleaseLabelClick = (release_label_group_id: number) => {
    setSelectedReleaseLabelGroupId(release_label_group_id);
    setExperimentModalOpen(true);
  };

  if (versionId === undefined && promptTemplateVersions.length > 0) {
    return (
      <Navigate
        to={`/workspace/${user.activeWorkspaceId}/prompt/${promptId}/version/${promptTemplateVersions[0].number}`}
        replace={true}
      />
    );
  }

  const renderDynamicReleaseLabels = (promptVersionId: number) => {
    const items = dynamicReleaseLabelsByPromptRegistry[promptVersionId];
    if (!items) return null;

    return items.map(({ prompt_label_name, release_label_group_id }) => (
      <div
        className="group relative rounded-sm border border-dashed border-blue-500 px-2 py-0.5 text-xs font-medium text-blue-400 hover:bg-blue-50"
        onClick={() => handleDynamicReleaseLabelClick(release_label_group_id)}
        key={prompt_label_name}
      >
        {prompt_label_name}
      </div>
    ));
  };

  const renderLabels = (
    labels: promptLabel.Response[],
    selected: boolean,
    promptVersionId: number,
  ) => {
    return labels?.map((label) => (
      <div
        key={`${label.name}-${label.id}`}
        className={classNames(
          "group relative rounded-sm border px-2 py-0.5 text-xs font-medium",
          selected
            ? "border-gray-400 bg-blue-50 text-gray-600"
            : "border-gray-200 text-gray-500",
          "hover:bg-red-100 hover:text-red-400",
          selected ? "hover:border-red-300" : "hover:border-red-200",
        )}
        onClick={() =>
          handleDeleteVersionLabel({
            name: label.name,
            id: label.id,
            promptId: Number(promptId),
            versionId: promptVersionId,
            onSuccess: () => {
              refetchLabels();
              refetchReleaseLabels();
            },
          })
        }
      >
        {label.name}
        <div className="absolute -right-1.5 -top-1.5">
          <XIcon className="hidden h-3 w-3 text-red-400 group-hover:block" />
        </div>
      </div>
    ));
  };

  const handleABTestButtonClick = (
    promptId: number,
    promptVersionId: number,
  ) => {
    if (allLabels.length === 0) {
      createABtest.mutate(
        {
          promptId,
          promptVersionId,
          workspaceId: user.activeWorkspaceId!,
        },
        {
          onSuccess: (res) => {
            if (res?.success && res?.release_label_group?.id) {
              navigate(
                `/workspace/${user.activeWorkspaceId}/ab-releases/prompt-release-group/${res?.release_label_group?.id}?prompt=${promptId}`,
              );
            } else {
              displayErrorToast(
                res?.message ?? "There's a problem with the AB Test request",
              );
            }
          },
          onError: () => {
            displayErrorToast("There's a problem with the AB Test request");
          },
        },
      );

      return;
    }

    setVersionIdForABTest(promptVersionId);
    setLabelsSelectOpen(true);
  };

  return (
    <>
      <div className="rounded-md border border-gray-300 bg-white drop-shadow-md">
        <div className="flex items-center justify-between rounded-t-lg border-b border-gray-200 bg-gray-50 px-3 py-1">
          <h2 className="text-sm font-normal text-gray-600">
            Template Versions
          </h2>
          <button
            onClick={() => setShowVersionList(false)}
            className="text-xs text-gray-500 hover:text-gray-700"
          >
            Hide
          </button>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center py-4">
            <LoadingSpinner size={5} />
          </div>
        ) : null}
        <Tab.List className="relative max-h-[500px] overflow-y-scroll">
          {promptTemplateVersions.map(
            ({
              id,
              created_at,
              labels,
              commit_message,
              report_id,
              author,
              number,
            }) => (
              <Tab
                key={id}
                className={classNames(
                  "w-full overflow-x-hidden",
                  selectedVersionNumber === number ? "bg-blue-100" : "",
                )}
              >
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link
                        to={`/workspace/${user.activeWorkspaceId}/prompt/${promptId}/version/${number}`}
                      >
                        <div className="relative mx-6 border-l border-gray-300 p-2 text-start">
                          <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-gray-500 bg-white"></div>
                          <div className="mx-6 flex items-center justify-between">
                            <div className="flex-shrink-0">
                              <div className="text-start">
                                {`Version ${number}`}
                                {/* Show badge if there are requests & not selected+loading */}
                                {requestCountsData &&
                                requestCountsData[`${number}`] &&
                                requestCountsData[`${number}`] > 0 ? (
                                  <div className="ml-2 inline-flex items-center rounded-full bg-gray-300 px-2 py-0.5 text-xs font-medium text-gray-800">
                                    {requestCountFormatter.format(
                                      requestCountsData[number],
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="ml-6 flex items-center justify-between">
                            <div className="truncate overflow-ellipsis py-0.5 text-xs text-gray-500">
                              <span>{moment(created_at).fromNow()}</span>
                              {author && (
                                <>
                                  <span className="px-1 text-gray-400">•</span>
                                  <User className="inline h-3 w-auto pr-1" />
                                  <span className="truncate overflow-ellipsis">
                                    {author}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          {commit_message && (
                            <div className="ml-6 flex items-center justify-between">
                              <div className="py-0.5 text-xs text-gray-600">
                                <ChatIcon
                                  className="mr-1 inline h-4 w-auto pb-0.5"
                                  aria-hidden="true"
                                />
                                {commit_message}
                              </div>
                            </div>
                          )}
                          {versionEvaluationScoresData[id] && (
                            <div className="ml-6 flex items-center justify-between">
                              <div className="py-0.5 text-xs text-gray-600">
                                {reportScoreForVersionList(
                                  versionEvaluationScoresData[id],
                                )}
                              </div>
                            </div>
                          )}
                          {report_id && !versionEvaluationScoresData[id] && (
                            <div className="ml-6 flex items-center justify-between">
                              <div className="py-0.5 text-xs text-gray-600">
                                <div className="flex items-center justify-between">
                                  <CheckIcon
                                    className="mr-1 inline h-3.5 w-auto"
                                    aria-hidden="true"
                                  />
                                  <div className="text-xs">Evaluated</div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="ml-6 flex items-center justify-between">
                            <div className="flex flex-wrap items-center gap-2 pt-1">
                              {renderLabels(
                                labels,
                                selectedVersionNumber === number,
                                id,
                              )}
                              {renderDynamicReleaseLabels(id)}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </TooltipTrigger>
                    <TooltipPrimitive.Portal>
                      <TooltipPrimitive.Content
                        className={`flex max-w-md translate-x-12 translate-y-10 flex-wrap items-center gap-1 rounded-md text-xs text-black`}
                      >
                        <div
                          onClick={() => {
                            setVersionToAddLabel({
                              versionId: id,
                              onSuccess: () => {
                                refetchReleaseLabels();
                                refetchLabels();
                              },
                            });
                            setOpenLabelForm(true);
                          }}
                          className={`flex cursor-pointer items-center rounded-md border border-primary bg-white p-2 text-gray-700 shadow-md hover:bg-gray-50 hover:text-gray-900`}
                        >
                          <PlusIcon className="mr-1 inline h-3 w-3" />
                          {"Add Release Label"}
                        </div>
                        {!dynamicReleaseLabelsByPromptRegistry?.[id] &&
                          allLabels.length !== 0 && (
                            <div
                              onClick={() =>
                                handleABTestButtonClick(Number(promptId), id)
                              }
                              className={`flex cursor-pointer items-center rounded-md border border-primary bg-white p-2 text-gray-700 shadow-md hover:bg-gray-50 hover:text-gray-900`}
                            >
                              <PlusIcon className="mr-1 inline h-3 w-3" />
                              {"Start A/B Test"}
                            </div>
                          )}
                        {report_id && (
                          <Link
                            to={`/workspace/${user.activeWorkspaceId}/evaluate/reports/${report_id}`}
                            className={`flex cursor-pointer items-center rounded-md border border-primary bg-white p-2 text-gray-700 shadow-md hover:bg-gray-50 hover:text-gray-900`}
                          >
                            <ExternalLinkIcon className="mr-1 inline h-3 w-3" />
                            {"Eval Report"}
                          </Link>
                        )}
                        {selectedVersionNumber !== number && (
                          <Link
                            to={`/workspace/${user.activeWorkspaceId}/prompt/${promptId}/version/${selectedVersionNumber}/diff/${number}`}
                            className={`flex cursor-pointer items-center rounded-md border border-primary bg-white p-2 text-gray-700 shadow-md hover:bg-gray-50 hover:text-gray-900`}
                          >
                            <MagnifyingGlassIcon className="mr-1 inline h-3 w-3" />
                            {"Diff View"}
                          </Link>
                        )}
                      </TooltipPrimitive.Content>
                    </TooltipPrimitive.Portal>
                  </Tooltip>
                </TooltipProvider>
              </Tab>
            ),
          )}
          {hasNextPage && (
            <div ref={ref} className="flex justify-center py-2">
              <LoadingSpinner size={5} />
            </div>
          )}
        </Tab.List>
      </div>
      {isExperimentModalOpen && selectedReleaseLabelGroupId && (
        <ExperimentModal
          promptId={promptId}
          releaseLabelGroupId={selectedReleaseLabelGroupId}
          setOpen={setExperimentModalOpen}
        />
      )}
      <LabelsSelect
        promptVersion={{
          prompt_id: Number(promptId),
          version_id: versionIdForABTest!,
        }}
        open={isLabelsSelectOpen}
        labels={allLabels}
        isLoading={isLoading}
        setOpen={setLabelsSelectOpen}
      />
    </>
  );
};
