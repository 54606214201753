import { Braces, EyeIcon } from "lucide-react";
import React, { useCallback, useRef, useState } from "react";
import { Button } from "./button";
import Checkbox from "./checkbox";
import { BasicTooltip } from "./tooltip";

interface CompareColumnLayoutProps {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  minWidth?: number;
  columnNames?: string[];
  disableInteractive?: boolean;
  disableJson?: boolean;

  minimal?: boolean;
  gap?: string | number;
  defaultOpen?: {
    interactive?: boolean;
    json?: boolean;
  };
}

// Hooks
const useColumnVisibility = (
  disableInteractive?: boolean,
  defaultOpen?: { interactive?: boolean; json?: boolean },
) => {
  const [isInteractiveVisible, setIsInteractiveVisible] = useState(
    defaultOpen?.interactive ?? !disableInteractive,
  );
  const [isJsonVisible, setIsJsonVisible] = useState(
    defaultOpen?.json ?? false,
  );

  return {
    isInteractiveVisible,
    setIsInteractiveVisible,
    isJsonVisible,
    setIsJsonVisible,
  };
};

const useResizableWidth = (minWidth: number) => {
  const [leftWidth, setLeftWidth] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const newLeftWidth =
          ((e.clientX - containerRect.left) /
            containerRef.current.offsetWidth) *
          100;

        if (newLeftWidth >= minWidth && newLeftWidth <= 100 - minWidth) {
          setLeftWidth(newLeftWidth);
        }
      }
    },
    [isDragging, minWidth],
  );

  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return {
    leftWidth,
    handleMouseDown,
    containerRef,
  };
};

// Components
const ViewToggleButton = ({
  icon: Icon,
  label,
  isVisible,
  onClick,
  disabled,
}: {
  icon: React.ElementType;
  label: string;
  isVisible: boolean;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <Button variant={"outline"} size="sm" onClick={onClick}>
    <Icon className="mr-1 h-4 w-4" />
    {label}
    <span className=" ml-2 inline-flex items-center gap-1">
      {disabled && (
        <BasicTooltip content="Unknown JSON Schema" side="bottom">
          <span className="text-yellow-500">⚠</span>
        </BasicTooltip>
      )}
      <Checkbox
        checked={isVisible}
        className="h-3 w-3 rounded border-gray-300"
      />
    </span>
  </Button>
);

const Column = ({
  children,
  flexBasis,
}: {
  children: React.ReactNode;
  flexBasis: string | number;
}) => (
  <div
    className="flex min-h-0 flex-col overflow-visible"
    style={{ flex: flexBasis }}
  >
    <div className="flex-1 overflow-visible">{children}</div>
  </div>
);
const Divider = ({ onMouseDown }: { onMouseDown: () => void }) => (
  <div
    className="group relative flex min-h-0 w-4 cursor-col-resize select-none bg-transparent"
    onMouseDown={onMouseDown}
    role="separator"
    aria-orientation="vertical"
  >
    <div className="absolute left-0 right-0 h-full bg-blue-100/0 transition-colors duration-150 group-hover:bg-blue-100/50" />
    <div className="absolute left-1/2 h-full w-px -translate-x-1/2 bg-gray-200 transition-all duration-150 group-hover:bg-blue-600" />
    <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-150 group-hover:opacity-100">
      <div className="mx-1 h-12 w-[6px] rounded-full bg-blue-500" />
    </div>
  </div>
);

export const CompareColumnLayout: React.FC<CompareColumnLayoutProps> = ({
  leftComponent,
  rightComponent,
  minWidth = 20,
  columnNames = ["Interactive Editor", "JSON Editor"],
  disableInteractive = false,
  disableJson = false,
  minimal = false,
  gap = "0.5rem",
  defaultOpen,
}) => {
  const {
    isInteractiveVisible,
    setIsInteractiveVisible,
    isJsonVisible,
    setIsJsonVisible,
  } = useColumnVisibility(disableInteractive, defaultOpen);

  const { leftWidth, handleMouseDown, containerRef } =
    useResizableWidth(minWidth);

  const isSplitView = minimal ? true : isInteractiveVisible && isJsonVisible;

  const handleInteractiveClick = () => {
    if (!isInteractiveVisible && !isJsonVisible) {
      setIsInteractiveVisible(true);
    } else {
      setIsInteractiveVisible(!isInteractiveVisible);
    }
  };

  const handleJsonClick = () => {
    if (disableJson) return;
    if (!isInteractiveVisible && !isJsonVisible) {
      setIsJsonVisible(true);
    } else {
      setIsJsonVisible(!isJsonVisible);
    }
  };

  return (
    <div className="flex h-full min-h-0 w-full flex-col">
      {!minimal && (
        <div className="sticky top-0 z-20 mb-3 flex items-center gap-2 border-b border-gray-200 bg-white pb-2">
          <ViewToggleButton
            icon={EyeIcon}
            label={columnNames[0]}
            isVisible={isInteractiveVisible}
            onClick={handleInteractiveClick}
            disabled={disableInteractive}
          />
          <ViewToggleButton
            icon={Braces}
            label={columnNames[1]}
            isVisible={isJsonVisible}
            onClick={handleJsonClick}
            disabled={disableJson}
          />
        </div>
      )}

      <div
        ref={containerRef}
        className="flex min-h-0 w-full flex-1 flex-row justify-end overflow-visible"
        style={{ gap }}
      >
        {!minimal && !isInteractiveVisible && !isJsonVisible ? (
          <div className="flex h-full w-full items-center justify-center text-gray-500">
            Select a view option above to get started
          </div>
        ) : (
          <>
            {(minimal || isInteractiveVisible) && (
              <Column flexBasis={isSplitView ? leftWidth : "100%"}>
                {leftComponent}
              </Column>
            )}

            {isSplitView && <Divider onMouseDown={handleMouseDown} />}

            {(minimal || isJsonVisible) && (
              <Column flexBasis={isSplitView ? 100 - leftWidth : "100%"}>
                {rightComponent}
              </Column>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CompareColumnLayout;
