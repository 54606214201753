import { FC } from "react";
import Feature from "@/components/Home/Features/Feature";
import versionsScreenshot from "@/assets/home_guide/versions.png";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useUser } from "@/context/user-context";
import mixpanel from "@/mixpanel";

type PromptTemplateFeatureProps = {
  handleCloseClick: () => void;
};

const PromptTemplateFeature: FC<PromptTemplateFeatureProps> = ({
  handleCloseClick,
}) => {
  const userContext = useUser();
  return (
    <Feature onCloseClick={handleCloseClick}>
      <div className="flex">
        <div className="w-7/12">
          <img
            alt="Feature"
            className="w-full rounded-lg"
            src={versionsScreenshot}
          />
        </div>
        <div className="ml-6 w-5/12">
          <h2 className="mt-6 text-2xl font-medium">Prompt template editor</h2>
          <p className="mt-2 text-base text-gray-500">
            Visually create, edit, and version your prompt templates. Track
            usage & allow the whole team to be a prompt engineer.
            <br />
            <br />
            Prompts shouldn't be hardcoded in your codebase. Specify model
            selection, parameters, and function schema.
          </p>
          <Link
            to={`/workspace/${userContext?.activeWorkspaceId}/create-prompt`}
            onClick={() => {
              mixpanel.track("Clicked Create Template", {
                workspace_id: userContext?.activeWorkspaceId,
                source: "UI",
              });
            }}
          >
            <Button className="mt-4" variant="secondaryLightOutline">
              Create a Prompt
            </Button>
          </Link>
        </div>
      </div>
    </Feature>
  );
};

export default PromptTemplateFeature;
