import { useUser } from "@/context/user-context";
import { PromptVersion } from "@/types/apiGetters";
import { useNavigate } from "react-router-dom";

const useNavigateToPromptVersion = (promptVersion?: PromptVersion) => {
  const user = useUser();
  const navigate = useNavigate();

  const workspaceId = user?.activeWorkspaceId!;
  return () =>
    navigate(
      `/workspace/${workspaceId}/prompt/${promptVersion?.prompt_id}/version/${promptVersion?.number}`,
    );
};

export default useNavigateToPromptVersion;
