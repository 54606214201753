export default function NotFound() {
  return (
    <>
      <h1 className="text-3xl">Error: 404</h1>
      <h2 className="pt-2 text-sm font-normal">
        Page not found. Try refreshing or{" "}
        <a
          className="text-sm text-[#1D4ED8] hover:text-[#1D4ED8]/90 hover:underline"
          href="/home"
        >
          returning home
        </a>
        .
      </h2>
    </>
  );
}
