import { useEffect, useRef, useState } from "react";

interface CheckOverflowHookProps {
  ignoreObserver?: boolean;
  handleOnOverflow?: (ref: HTMLElement) => void;
  handleOnNotOverflow?: (ref: HTMLElement) => void;
}

export const useCheckOverflow = <ElementT extends HTMLElement>({
  ignoreObserver,
  handleOnOverflow,
  handleOnNotOverflow,
}: CheckOverflowHookProps) => {
  const ref = useRef<ElementT>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (!ref.current) {
        return;
      }

      const isOverflowingContent =
        ref.current.scrollWidth > ref.current.clientWidth ||
        ref.current.scrollHeight > ref.current.clientHeight;

      setIsOverflowing(isOverflowingContent);

      if (isOverflowingContent) {
        handleOnOverflow?.(ref.current);
      } else {
        handleOnNotOverflow?.(ref.current);
      }
    };

    const manageObserver = (action: "observe" | "unobserve") => {
      if (ref.current && !ignoreObserver) {
        const observer = new ResizeObserver(checkOverflow);
        observer[action](ref.current);
      }
    };

    manageObserver("observe");

    return () => {
      manageObserver("unobserve");
    };
  }, [handleOnNotOverflow, handleOnOverflow, ignoreObserver]);

  return {
    ref,
    isOverflowing,
  };
};
