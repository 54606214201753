import { observer } from "mobx-react-lite";
import {
  forwardRef,
  SyntheticEvent,
  useImperativeHandle,
  useState,
} from "react";

import { runInAction } from "mobx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../dropdown-menu";
import { useFilterStore, withFilterStore } from "./filter-store";
import FilterList from "./FilterList";
import { FilterType, humanReadableFilterTypes } from "./types";

const FilterConfigurator = forwardRef((_, ref) => {
  const filterStore = useFilterStore();
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    filterStore,
  }));

  const handleAddFilter = (filterType: FilterType) => {
    return (e: SyntheticEvent) => {
      setIsOpen(false);
      runInAction(() => filterStore.addFilter(filterType));
    };
  };

  const handleRemoveFilter = (id: string) => {
    return () => {
      runInAction(() => filterStore.removeFilter(id));
    };
  };

  return (
    <div className="mt-3 flex flex-col gap-y-1">
      <FilterList onRemove={handleRemoveFilter} />
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger className="mt-2">Add filter</DropdownMenuTrigger>
        <DropdownMenuContent>
          {filterStore.availableFilters
            .map((filter) => [filter, humanReadableFilterTypes[filter]])
            .map(([key, label]) => (
              <DropdownMenuItem
                key={key}
                onClick={handleAddFilter(key as FilterType)}
              >
                {label}
              </DropdownMenuItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
});

export default observer(withFilterStore(FilterConfigurator));
