import { Folder } from "@/types/folders";

export const formatFoldersWithSubFolders = (folders: Folder[]) => {
  return (
    folders?.reduce((acc: Folder[], folder: Folder) => {
      const subFolders = folders.filter((f) => f.parent_id === folder.id);
      const newFolder = {
        ...folder,
        subFolders: subFolders,
      };

      acc.push(newFolder);

      return acc;
    }, []) ?? []
  );
};
