import { Folder } from "@/types/folders";

import { FolderItem } from "./FolderItem";
import { MoveToFolderModalContentProps } from "./types";

export const MoveToFolderModalContent = ({
  folderList,
  searchTerm,
  selectedFolderId,
  handleSelectFolder,
  currentFolder,
}: MoveToFolderModalContentProps) => {
  const rootFolders = folderList.filter((folder) => folder.parent_id === null);

  const renderFolderItems = (folders: Folder[]) => {
    return folders.map((folder) => (
      <FolderItem
        key={folder.id}
        folder={folder}
        selectedFolderId={selectedFolderId}
        handleSelectFolder={handleSelectFolder}
      />
    ));
  };

  if (searchTerm) {
    const filteredFolders = folderList.filter((folder) =>
      folder.name.includes(searchTerm),
    );
    return renderFolderItems(filteredFolders);
  }

  if (currentFolder) {
    const subFolders = currentFolder?.subFolders ?? [];
    if (!subFolders.length) {
      return (
        <div className="flex h-full w-full select-none items-center justify-center py-4 text-sm text-gray-400">
          No subfolders
        </div>
      );
    }
    return renderFolderItems(subFolders);
  }

  return renderFolderItems(rootFolders);
};
