import { ToastType } from "@/enums";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";

import { displayErrorToast } from "@/utils/toast";

import { FormEvent } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FolderModalProps } from "../types";
import { displayToast } from "@/utils/toast";
import { useState } from "react";
import { useAuth } from "@/context/auth-context";
import { useCreateFolder, useEditFolder } from "@/queries";

export const useCreateFolderModel = ({ folder, setOpen }: FolderModalProps) => {
  const [name, setName] = useState("");
  const activeWorkspace = useActiveWorkspace();
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const createFolder = useCreateFolder(userToken!);
  const editFolder = useEditFolder(userToken!);
  const { folderId } = useParams();

  useEffect(() => {
    if (folder) {
      setName(folder.name);
    }
  }, [folder]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const trimmedName = name.trim();
      if (!folder) {
        const res = await createFolder.mutateAsync({
          name: trimmedName,
          workspace_id: activeWorkspace!.id,
          parent_folder_id: folderId,
        });
        if (!res?.success) {
          displayErrorToast(res?.message || "Failed to create folder");
        } else {
          displayToast("Folder created", ToastType.success);
        }
      } else {
        const res = await editFolder.mutateAsync({
          folder_id: folder.id,
          name: trimmedName,
        });
        if (!res?.success) {
          displayErrorToast(res?.message || "Failed to update folder");
        } else {
          displayToast("Folder updated", ToastType.success);
        }
      }
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return {
    name,
    setName,
    handleClose,
    handleSubmit,
  };
};
