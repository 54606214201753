import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { FolderModalProps } from "./types";
import { useCreateFolderModel } from "./hooks";

export const FolderModal = ({ folder, setOpen }: FolderModalProps) => {
  const { name, setName, handleClose, handleSubmit } = useCreateFolderModel({
    folder,
    setOpen,
  });

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter folder name"
            required
            type="text"
            value={name}
          />
        </div>
        <div className="mt-4 text-center">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{folder ? "Rename" : "Create"} Folder</DialogTitle>
        </DialogHeader>
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};
