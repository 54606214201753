import { BREADCRUMBS_OFFSET } from "../constants";
import { FolderBreadcrumbsProps } from "../types";

export const useFolderBreadcrumbs = ({
  selectedFolderId,
  setSelectedFolderId,
  setCurrentFolder,
  setBreadcrumbs,
  breadcrumbs,
  folderList,
}: FolderBreadcrumbsProps) => {
  const handleSelectBreadcrumb = (folderId: number) => {
    setSelectedFolderId(folderId);

    const newCurrentFolder = folderList.find(
      (folder) => folder.id === folderId,
    );
    setCurrentFolder(newCurrentFolder ?? null);

    const index = breadcrumbs.findIndex((folder) => folder.id === folderId);
    setBreadcrumbs((prev) => [...prev.slice(0, index + 1)]);
  };

  const goHome = () => {
    setBreadcrumbs([]);
    setCurrentFolder(null);
    setSelectedFolderId(null);
  };

  const breadcrumbsLength = breadcrumbs.length - 1;
  let offset = breadcrumbsLength;
  const isOverLimit = breadcrumbsLength > BREADCRUMBS_OFFSET;

  if (isOverLimit) {
    offset = BREADCRUMBS_OFFSET;
  }

  const breadcrumbsToRender = breadcrumbs.slice(breadcrumbsLength - offset);
  const overLimitBreadcrumb = breadcrumbs[breadcrumbsLength - offset - 1];
  const isOnHome = selectedFolderId === null;

  return {
    handleSelectBreadcrumb,
    goHome,
    isOverLimit,
    breadcrumbsToRender,
    overLimitBreadcrumb,
    isOnHome,
  };
};
