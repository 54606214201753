import { FilterIcon } from "@heroicons/react/outline";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import EmptyPlaceholder from "../EmptyPlaceholder";
import DateRangeFilter from "./DateRangeFilter";
import Filter from "./Filter";
import { useFilterStore } from "./filter-store";
import MetadataFilter from "./MetadataFilter";
import ScoreFilter from "./ScoreFilter";
import TagsFilter from "./TagsFilter";
import { FilterType, MetadataFilterType } from "./types";

const FilterList = observer(
  ({ onRemove }: { onRemove: (id: string) => () => void }) => {
    const filterStore = useFilterStore();

    return (
      <>
        <div className="mb-3 flex items-center justify-between">
          <span className="rounded-full bg-blue-100 px-3 py-1 text-sm text-blue-600   hover:bg-blue-200">
            {filterStore.filters.length} filters
          </span>
          <button
            className="rounded px-2 py-1 text-xs font-medium text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700"
            onClick={() => filterStore.clearFilters()}
          >
            Clear
          </button>
        </div>
        {filterStore.filters.length === 0 && (
          <EmptyPlaceholder
            message="No filters selected"
            icon={<FilterIcon className="h-5 w-5 text-gray-400" />}
          />
        )}
        {filterStore.filters.map((filter, index) => {
          return (
            <Filter onRemove={onRemove(filter.id)} key={index}>
              {filter.type === FilterType.METADATA_AND ? (
                <MetadataFilter
                  onUpdate={(key, value) => {
                    runInAction(() =>
                      filterStore.updateFilter(filter.id, {
                        type: FilterType.METADATA_AND,
                        property: key,
                        values: value,
                      }),
                    );
                  }}
                />
              ) : filter.type === FilterType.METADATA_OR ? (
                <MetadataFilter
                  type={MetadataFilterType.OR}
                  onUpdate={(key, value) => {
                    runInAction(() =>
                      filterStore.updateFilter(filter.id, {
                        type: FilterType.METADATA_OR,
                        property: key,
                        values: value,
                      }),
                    );
                  }}
                />
              ) : filter.type === FilterType.SCORE ? (
                <ScoreFilter
                  onUpdate={(property, operator, value) =>
                    runInAction(() =>
                      filterStore.updateFilter(filter.id, {
                        property,
                        operator,
                        value,
                      }),
                    )
                  }
                />
              ) : filter.type === FilterType.DATE_RANGE ? (
                <DateRangeFilter
                  onChange={function (start: string, end: string): void {
                    filterStore.updateFilter(filter.id, {
                      start,
                      end,
                    });
                  }}
                />
              ) : filter.type === FilterType.TAGS_AND ? (
                <TagsFilter
                  type={"AND"}
                  onChange={(tags) => {
                    filterStore.updateFilter(filter.id, { tags });
                  }}
                />
              ) : filter.type === FilterType.TAGS_OR ? (
                <TagsFilter
                  type={"OR"}
                  onChange={(tags) => {
                    filterStore.updateFilter(filter.id, { tags });
                  }}
                />
              ) : (
                ""
              )}
            </Filter>
          );
        })}
      </>
    );
  },
);

export default FilterList;
