import { useState } from "react";
import { Input } from "../../input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../select";

interface RestrictedComparionProps {
  keys: string[];
  onChange: (key: string, operator: string, value: string) => void;
  placeholder?: string;
}

const RestrictedComparion = ({
  keys,
  onChange,
  placeholder = "Select a key",
}: RestrictedComparionProps) => {
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [operator, setOperator] = useState<string>("=");
  const [value, setValue] = useState<string>("");

  const operators = [
    { label: "=", value: "=" },
    { label: "!=", value: "!=" },
    { label: ">", value: ">" },
    { label: "<", value: "<" },
    { label: ">=", value: ">=" },
    { label: "<=", value: "<=" },
  ];

  const handleKeyChange = (key: string) => {
    setSelectedKey(key);
    onChange(key, operator, value);
  };

  const handleOperatorChange = (op: string) => {
    setOperator(op);
    onChange(selectedKey, op, value);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(selectedKey, operator, e.target.value);
  };

  return (
    <div className="flex gap-2">
      <Select onValueChange={handleKeyChange}>
        <SelectTrigger className="w-[200px] bg-white">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent
          position="popper"
          className="max-h-[200px] overflow-auto"
        >
          {keys.map((key, index) => (
            <SelectItem key={index} value={key}>
              {key}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {selectedKey && (
        <>
          <Select onValueChange={handleOperatorChange} defaultValue="=">
            <SelectTrigger className="w-[100px] bg-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {operators.map((op) => (
                <SelectItem key={op.value} value={op.value}>
                  {op.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Input
            type="number"
            value={value}
            onChange={handleValueChange}
            placeholder="Enter value"
          />
        </>
      )}
    </div>
  );
};

export default RestrictedComparion;
