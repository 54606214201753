export interface BaseFilter<T extends FilterType> {
  type: T;
  id: string;
}

export interface MetadataFilter<
  T extends FilterType.METADATA_AND | FilterType.METADATA_OR,
> extends BaseFilter<T> {
  property: string;
  values: Set<string>;
}

export interface ScoreFilter extends BaseFilter<FilterType.SCORE> {
  property: string;
  operator: string;
  value?: number;
}

export interface DateRangeFilter extends BaseFilter<FilterType.DATE_RANGE> {
  start?: Date;
  end?: Date;
}

export interface TagsFilter<T extends FilterType.TAGS_AND | FilterType.TAGS_OR>
  extends BaseFilter<T> {
  tags: string[];
}

export type Filter =
  | MetadataFilter<FilterType.METADATA_AND>
  | MetadataFilter<FilterType.METADATA_OR>
  | ScoreFilter
  | DateRangeFilter
  | TagsFilter<FilterType.TAGS_AND>
  | TagsFilter<FilterType.TAGS_OR>;

export enum MetadataFilterType {
  OR = "OR",
  AND = "AND",
}

export enum FilterType {
  METADATA_AND = "METADATA_AND",
  METADATA_OR = "METADATA_OR",
  SCORE = "SCORE",
  DATE_RANGE = "DATE_RANGE",
  TAGS_AND = "TAGS_AND",
  TAGS_OR = "TAGS_OR",
}

export const humanReadableFilterTypes: { [key in FilterType]: string } = {
  [FilterType.METADATA_AND]: "Metadata AND",
  [FilterType.METADATA_OR]: "Metadata OR",
  [FilterType.SCORE]: "Score",
  [FilterType.DATE_RANGE]: "Date Range",
  [FilterType.TAGS_OR]: "Tags OR",
  [FilterType.TAGS_AND]: "Tags AND",
};

export const filterTypeValues: FilterType[] = Object.values(FilterType);
