import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import {
  useDatasetRowsFromFilterParams,
  usePromptRegistryObjects,
} from "@/queries";
import { displayToast } from "@/utils/toast";
import { parseUrlParams } from "@/utils/utils";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import LimitField from "./ui/fields/LimitField";
import PromptTemplateField from "./ui/fields/PromptTemplateField";
import FilterConfigurator from "./ui/FilterConfigurator";
import { IFilterStore } from "./ui/FilterConfigurator/filter-store";

type DatasetRowsFromFilterParamsModalProps = {
  dataset_id: number;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
};

const DatasetRowsFromFilterParamsModal: FC<
  DatasetRowsFromFilterParamsModalProps
> = ({ dataset_id, setOpen, isOpen }) => {
  const [limit, setLimit] = useState<number | undefined>(undefined);
  const [promptTemplate, setPromptTemplate] = useState<
    { id: number; name: string; version_numbers?: number[] } | undefined
  >(undefined);
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken!;
  const workspaceId = userContext?.activeWorkspaceId!;
  const filterStore = observable.box<IFilterStore>();

  const { mutateAsync: datasetRowsFromFilterParams, isLoading } =
    useDatasetRowsFromFilterParams(userToken!);

  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptTemplates =
    promptRegistryObjects?.data?.pages.flatMap((page) => page.items) || [];
  const promptTemplatesIsLoading = promptRegistryObjects.isLoading;

  const urlParams = new URLSearchParams(window.location.search);

  const { tags, metadata, favorites, query, scores } =
    parseUrlParams(urlParams);

  const handleClick = async () => {
    let queryParams: { [key: string]: any } = {};

    if (query) {
      queryParams["q"] = query;
    }

    if (tags.length > 0) {
      queryParams["tags"] = tags;
    }

    if (metadata.length > 0) {
      queryParams["metadata"] = metadata;
    }

    if (scores.length > 0) {
      queryParams["scores"] = scores.map((score) => ({
        name: score.name,
        operator: score.operator,
        value: score.value,
      }));
    }

    if (favorites.length > 0 && favorites[0] === "true") {
      queryParams["starred"] = true;
    }

    try {
      const res = await datasetRowsFromFilterParams({
        dataset_id,
        ...(filterStore.get()?.payload! || {}),
        limit: limit || undefined,
        prompt_template: promptTemplate || undefined,
        q: queryParams.q,
        starred: queryParams.starred,
      });
      if (!res.success) {
        displayToast(res?.message || "Error adding requests", ToastType.error);
        return;
      }
      displayToast(
        res?.message || "Requests added successfully",
        ToastType.success,
      );
    } catch (error: unknown) {
      displayToast("Error adding requests", ToastType.error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Data From Request History</DialogTitle>
        </DialogHeader>
        <div className="grid max-w-lg grid-cols-3 items-center gap-y-2 py-2">
          <LimitField setLimit={setLimit} />
          <PromptTemplateField
            promptTemplate={promptTemplate}
            setPromptTemplate={setPromptTemplate}
            promptTemplatesIsLoading={promptTemplatesIsLoading}
            promptTemplates={promptTemplates}
          />
        </div>
        <div className="mb-3 max-h-96 overflow-auto rounded-sm border border-gray-200 bg-gray-100 px-4 pb-4">
          <FilterConfigurator
            ref={(filterStoreRef) => {
              if (filterStoreRef) {
                filterStore.set(filterStoreRef as IFilterStore);
              }
            }}
          />
        </div>
        <Button isLoading={isLoading} onClick={handleClick}>
          Add Requests
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default observer(DatasetRowsFromFilterParamsModal);
