import { Message, PromptBlueprint, Template } from "@/types";
import { PromptVersion } from "@/types/apiGetters";
import { getStringContent } from "./utils";

export type OneLevelNestedPaths<T> = keyof T | `${string & keyof T}.${string}`;

const messageToString = (message: Message) => {
  if (message.role === "assistant") {
    if (message.function_call) return message.function_call.arguments;
  }
  return getStringContent(message);
};

const getTemplateValue = (template: Template) => {
  if (template.type === "completion") return getStringContent(template);
  return template.messages.map(messageToString).join("\n---\n");
};

export const getPromptDataForDiffView = (
  promptVersion: PromptVersion | PromptBlueprint,
  fieldsToShow: OneLevelNestedPaths<PromptVersion | PromptBlueprint>[],
) => {
  const newData = fieldsToShow.reduce((acc, field) => {
    if (field.includes(".")) {
      const [parentKey, childKey] = field.split(".") as [
        keyof (PromptVersion | PromptBlueprint),
        string,
      ];

      const nestedValue =
        promptVersion[parentKey]?.[
          childKey as keyof (typeof promptVersion)[typeof parentKey]
        ];

      return {
        ...acc,
        [childKey]: nestedValue,
      };
    }

    let value = promptVersion[field as keyof (PromptVersion | PromptBlueprint)];

    if (field === "prompt_template") {
      value = getTemplateValue(promptVersion.prompt_template);
    }

    return {
      ...acc,
      [field]: value,
    };
  }, {});

  return JSON.stringify(newData, null, 2).replace(/\\n/g, "\n");
};
