import { ReactNode } from "react";
import noiseBackground from "@/assets/noise-background.png";
import logo from "@/assets/logo/white-logo.svg";
import { Flip, ToastContainer } from "react-toastify";

export default function LoginLayout({ children }: { children: ReactNode }) {
  return (
    <div className="relative flex h-full flex-col overflow-hidden bg-gradient-to-br from-[#1439A0] to-[#B1C6FF]">
      <img
        src={noiseBackground}
        alt="noise"
        className="absolute left-0 top-0 h-full w-full object-cover opacity-[40%]"
      />
      <div className="absolute z-[51] p-8 phone:p-6">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <main className="z-50 flex w-full flex-1 items-center justify-center px-4">
        <div className="flex h-fit w-full max-w-[424px] flex-col gap-2 rounded-[30px] border border-gray-200 bg-white p-8 shadow-2xl phone:p-4">
          {children}
        </div>
        <ToastContainer
          autoClose={3000}
          closeOnClick
          draggable
          hideProgressBar
          newestOnTop
          pauseOnFocusLoss
          pauseOnHover
          position="top-right"
          rtl={false}
          transition={Flip}
        />
      </main>
    </div>
  );
}
