import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { MoveToFolderModalProps } from "./types";
import { FolderBreadcrumbs } from "./FolderBreadcrumbs";
import { Navigation } from "./Navigation";
import { useMoveToFolderModal } from "./hooks";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { MoveToFolderModalContent } from "./MoveToFolderModalContent";

export const MoveToFolderModal = ({
  open,
  prompt,
  setOpen,
}: MoveToFolderModalProps) => {
  const {
    selectedFolderId,
    handleSelectFolder,
    handleGoBack,
    handleMove,
    currentFolder,
    breadcrumbs,
    folderList,
    setBreadcrumbs,
    setCurrentFolder,
    setSelectedFolderId,
    searchTerm,
    setSearchTerm,
  } = useMoveToFolderModal(prompt, setOpen);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="flex h-full max-h-[384px] flex-col gap-2">
        <DialogHeader>
          <DialogTitle className="break-all text-lg font-medium">
            Move {prompt?.prompt_name}
          </DialogTitle>
          <FolderBreadcrumbs
            folderList={folderList}
            breadcrumbs={breadcrumbs}
            selectedFolderId={selectedFolderId}
            setBreadcrumbs={setBreadcrumbs}
            setCurrentFolder={setCurrentFolder}
            setSelectedFolderId={setSelectedFolderId}
          />
        </DialogHeader>
        <div className="relative">
          <label
            htmlFor="folder-search"
            className="absolute left-2 top-1/2 w-auto -translate-y-1/2 text-gray-500"
          >
            <MagnifyingGlassIcon className="h-5 w-auto" />
          </label>
          <input
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            id="folder-search"
            type="text"
            className="w-full rounded-md border border-gray-300 pl-8 pr-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            placeholder="Search..."
          />
        </div>
        <div className="h-full max-h-[230px] overflow-y-auto">
          <MoveToFolderModalContent
            folderList={folderList}
            searchTerm={searchTerm}
            selectedFolderId={selectedFolderId}
            handleSelectFolder={handleSelectFolder}
            currentFolder={currentFolder}
          />
        </div>
        <Navigation
          setOpen={setOpen}
          handleGoBack={handleGoBack}
          currentFolder={currentFolder}
          selectedFolderId={selectedFolderId}
          handleMove={handleMove}
          promptFolderId={prompt?.folder_id ?? null}
        />
      </DialogContent>
    </Dialog>
  );
};
