import { PlayIcon } from "lucide-react";
import { ReactNode, forwardRef } from "react";

interface EmptyPlaceholderProps {
  icon?: ReactNode;
  message?: string;
  className?: string;
}

const EmptyPlaceholder = forwardRef<HTMLDivElement, EmptyPlaceholderProps>(
  (
    {
      icon = <PlayIcon className="h-5 w-5 text-gray-400" />,
      message = "Run the prompt to see outputs here",
      className = "",
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={`flex h-24 w-full flex-col items-center justify-center gap-2 rounded-lg border border-gray-200 bg-gray-50 p-4 text-center ${className}`}
      >
        {icon}
        <span className="text-sm text-gray-500">{message}</span>
      </div>
    );
  },
);

EmptyPlaceholder.displayName = "EmptyPlaceholder";

export default EmptyPlaceholder;
