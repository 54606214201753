import { useAuth } from "@/context/auth-context";
import { useGetReport } from "@/queries";
import { ComponentProps, forwardRef } from "react";

const EvaluationTriggerText = forwardRef<
  HTMLSpanElement,
  ComponentProps<"span"> & { evaluationId?: number }
>(({ children, evaluationId, ...props }, ref) => {
  const userToken = useAuth()?.userToken || "";
  const { data, isLoading } = useGetReport(userToken!, evaluationId!);
  if (isLoading && evaluationId)
    return (
      <span ref={ref} className="text-gray-500" {...props}>
        {children} Loading...
      </span>
    );

  const report = data?.report;

  const isBlueprint = report?.is_blueprint;

  if (isBlueprint)
    return (
      <span ref={ref} {...props}>
        {children} {report.name}
      </span>
    );

  return (
    <span ref={ref} className="text-gray-500" {...props}>
      {children} {data?.name ?? "Select a pipeline..."}
    </span>
  );
});

export default EvaluationTriggerText;
