import { ExclamationIcon } from "@heroicons/react/outline";
import { FC, FormEvent, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { useCreateDatasetGroup } from "@/queries";

type DatasetGroupModalProps = {
  setOpen: (isOpen: boolean) => void;
  handleDatasetCreated: (response: any) => void;
};

const DatasetGroupModal: FC<DatasetGroupModalProps> = ({
  setOpen,
  handleDatasetCreated,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [name, setName] = useState("");
  const activeWorkspace = useActiveWorkspace();
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const { mutateAsync: createDatasetGroup, isLoading } = useCreateDatasetGroup(
    userToken!,
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await createDatasetGroup({
        name,
        workspace_id: activeWorkspace!.id,
      });

      if (response.success) {
        handleDatasetCreated(response);
        handleClose();
        setErrorMessage(null);
      } else if (response.error) {
        setErrorMessage(response.error);
      } else {
        setErrorMessage("Error creating dataset");
      }
    } catch (error: unknown) {
      setErrorMessage(
        typeof error === "string" ? error : "Error creating dataset",
      );
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter dataset name"
            required
            type="text"
            value={name}
          />
        </div>
        <div className="mt-4 text-center">
          <Button isLoading={isLoading} type="submit">
            Create
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Dataset</DialogTitle>
        </DialogHeader>
        {renderForm()}
        {errorMessage && (
          <div className="w-34 mx-auto mt-4 rounded-md bg-red-100 p-2 text-xs text-red-600">
            <ExclamationIcon className="mr-1 inline-block h-4 w-4" />
            {errorMessage}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DatasetGroupModal;
