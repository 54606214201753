import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptVersions } from "@/queries";
import { PromptVersion } from "@/types/apiGetters";

const useRedirectLogic = (
  prompt?: { id?: number },
  submittedVersion?: PromptVersion,
) => {
  const auth = useAuth();
  const user = useUser();
  const userToken = auth?.userToken!;
  const { data: promptVersions } = usePromptVersions(userToken, {
    workspaceId: user?.activeWorkspaceId!,
    promptRegistryId: prompt?.id,
  });

  const currentPromptVersion = promptVersions?.pages[0]?.items[0];
  const currentPromptVersionCreatedAt = currentPromptVersion?.created_at;
  const isCreatedMoreThanOneWeekAgo = Boolean(
    currentPromptVersionCreatedAt &&
      new Date(currentPromptVersionCreatedAt).getTime() <
        Date.now() - 7 * 24 * 60 * 60 * 1000,
  );

  const link = `/workspace/${user?.activeWorkspaceId!}/prompt/${submittedVersion?.prompt_id}/version/${submittedVersion?.number}`;

  const shouldShowTestContent =
    submittedVersion?.number &&
    submittedVersion.number > 2 &&
    isCreatedMoreThanOneWeekAgo;

  return { shouldShowTestContent, link };
};

export default useRedirectLogic;
