import TagInput from "@/components/ConditionalModal/TagInput";

interface TagsFilterProps {
  onChange: (tags: string[]) => void;
  type: string;
}

const TagsFilter = ({ onChange, type }: TagsFilterProps) => {
  return (
    <>
      <div className="mb-2 flex items-center gap-2">
        <label className="text-sm font-medium text-gray-700">Tag Filter</label>
        <span className="rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium">
          {type}
        </span>
      </div>

      <TagInput initialTags={[]} onTagsChange={onChange} />
    </>
  );
};

export default TagsFilter;
