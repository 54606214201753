export const formFields = [
  { name: "email", label: "Email", placeholder: "Email", type: "text" },
  {
    name: "name",
    label: "Full Name",
    placeholder: "Sam Altman", // Jared wanted an easter egg here :D
    type: "text",
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
  },
  {
    name: "verifyPassword",
    label: "Re-enter Password",
    placeholder: "Re-enter Password",
    type: "password",
  },
];
