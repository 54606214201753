import { Dialog, DialogContent, DialogOverlay } from "@radix-ui/react-dialog";
import { InlineWidget } from "react-calendly";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { useAuth } from "@/context/auth-context";
import { CalendlyLoadingSpinner } from "./CalendlyLoadingSpinner";
import { CalendlyModalProps } from "./types";
import mixpanel from "@/mixpanel";

export const CalendlyModal = ({
  title,
  heading,
  description,
  utm,
  url,
}: CalendlyModalProps) => {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const shouldShowModal = localStorage.getItem("showScheduleCallModal");

    if (shouldShowModal) {
      setIsOpen(true);
      localStorage.removeItem("showScheduleCallModal");
    }
  }, []);

  const handleSkip = () => {
    mixpanel.people.set({
      "Scheduled a Call": false,
    });
    setIsOpen(false);
  };

  const handleCalendlyEvent = (e: MessageEvent) => {
    if (e.data.event === "calendly.event_scheduled") {
      mixpanel.people.set({
        "Scheduled a Call": true,
      });
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      window.removeEventListener("message", handleCalendlyEvent);
      return;
    }

    window.addEventListener("message", handleCalendlyEvent);
  }, [isOpen]);

  return (
    <Dialog open={isOpen}>
      <DialogOverlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-500/50 backdrop-blur-sm" />
      <DialogContent className="top-50% left-50% absolute left-1/2 top-1/2 z-50 grid h-full max-h-[680px] w-full max-w-[1000px] -translate-x-1/2 -translate-y-1/2 grid-cols-[1fr_.5fr] items-center justify-center gap-6 overflow-hidden rounded-[46px] bg-white p-10">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <div className="text-md font-semibold text-blue-500 sm:text-sm">
              {heading}
            </div>
            <div className="text-3xl font-bold text-gray-900 phone:text-2xl">
              {title}
            </div>
            <div className="text-lg text-gray-600 phone:text-base">
              {description}
            </div>
          </div>
          <div>
            <Button onClick={handleSkip} size="lg">
              Skip
            </Button>
          </div>
        </div>
        <div className="relative h-[600px] w-[400px] overflow-hidden rounded-[18px] shadow-xl">
          <InlineWidget
            url={url}
            styles={{
              height: "100%",
              width: "100%",
            }}
            pageSettings={{
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
            }}
            prefill={{
              name: auth?.user?.name,
              email: auth?.user?.email,
            }}
            utm={utm}
            LoadingSpinner={CalendlyLoadingSpinner}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
