import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import { Button } from "./button";
import { BasicTooltip } from "./tooltip";

export const CopyButton: React.FC<{
  text: string;
  iconSize?: string;
  className?: string;
}> = ({ text, className, iconSize = "h-5 w-5" }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = async () => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <BasicTooltip content={isCopied ? "Copied!" : "Copy"}>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          copyText();
          setIsCopied(true);
        }}
        variant={"ghost"}
        size={"tinyIcon"}
        className={`group relative text-gray-600 hover:text-gray-800 focus:outline-none ${className}`}
        aria-label="Copy to clipboard"
      >
        <div className="relative">
          <AnimatePresence initial={false} mode="wait">
            {isCopied ? (
              <motion.svg
                key="check"
                initial={{ position: "absolute", scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.15, ease: "easeInOut" }}
                className={`absolute left-0 top-0 ${iconSize}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <motion.path
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 0.2, delay: 0.1 }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </motion.svg>
            ) : (
              <motion.svg
                key="copy"
                initial={{ position: "absolute", scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.15, ease: "easeInOut" }}
                className={`absolute left-0 top-0 ${iconSize}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <motion.path
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 0.2, delay: 0.1 }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </motion.svg>
            )}
          </AnimatePresence>
          <div className={`${iconSize}`} />
        </div>
      </Button>
    </BasicTooltip>
  );
};
