import { Input } from "../input";

interface CommitMessageProps {
  message: string;
  setMessage: (message: string) => void;
  label?: string;
}

const CommitMessage = ({ message, setMessage, label }: CommitMessageProps) => (
  <div className="flex flex-col gap-y-2">
    <span className="text-xs text-gray-500">
      {label || "Prompt version change message (Optional)"}
    </span>
    <Input
      placeholder="Enter a commit message"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
    />
  </div>
);

export default CommitMessage;
