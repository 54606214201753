import { PromptVersion } from "@/types/apiGetters";
import { displayErrorToast } from "@/utils/toast";
import { Button } from "../../button";
import { DialogFooter } from "../../dialog";
import ABTest from "./ABTest";
import BackTest from "./BackTest";

interface TestProps {
  submittedPromptVersion: PromptVersion;
  onClose: () => void;
}

const RunTests = ({ submittedPromptVersion, onClose }: TestProps) => {
  return (
    <>
      <div className="flex h-full flex-row gap-x-6">
        <BackTest
          promptVersion={submittedPromptVersion}
          setError={(err) => displayErrorToast(err)}
        />
        <ABTest promptVersion={submittedPromptVersion} />
      </div>
      <DialogFooter>
        <div className="flex justify-end gap-2">
          <Button variant={"outline"} onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogFooter>
    </>
  );
};

export default RunTests;
