import mixpanel from "mixpanel-browser";
import { User } from "./types/user";

const isProduction = process.env.NODE_ENV === "production";
const mixpanelProjectKey = isProduction
  ? process.env.REACT_APP_MIXPANEL_PROJECT_KEY
  : process.env.REACT_APP_DEV_MIXPANEL_TOKEN;

export interface MixpanelUser {
  $distinct_id: string;
  $email: string;
}

// Create mock mixpanel instance if no project key is set
const mockMixpanel = {
  track: () => {},
  identify: () => {},
  people: {
    set_once: () => {},
  },
};

const mixpanelInstance = mixpanelProjectKey ? mixpanel : mockMixpanel;

if (mixpanelProjectKey) {
  mixpanel.init(mixpanelProjectKey, {
    api_host: process.env.REACT_APP_MIXPANEL_PROXY || "",
    track_pageview: false,
    api_transport: "xhr",
    debug: !isProduction,
    ignore_dnt: true,
  });
}

export const identifyAndSetEmail = (user: User) => {
  mixpanelInstance.identify(user.id);
  mixpanelInstance.people.set_once({ $email: user.email });
};

export default mixpanelInstance;
