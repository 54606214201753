import { useAuth } from "@/context/auth-context";
import { useReportColumnsIncludePromptTemplateLatestVersion } from "@/queries";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

interface WarningProps {
  promptName: string;
  evaluationId?: number;
}

const Warning = ({ promptName, evaluationId }: WarningProps) => {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const reportWarning = useReportColumnsIncludePromptTemplateLatestVersion(
    userToken,
    evaluationId!,
    promptName,
  );
  if (reportWarning) return null;
  return (
    <div className="py-3 text-sm font-normal text-red-600">
      <ExclamationTriangleIcon className="mr-1 inline-block h-4 w-4" />
      <strong className="font-bold">Warning:</strong> This pipeline will not run
      the prompt you are about to save. Make sure there is a step that uses the
      "latest" version of this prompt template.{" "}
      <a
        href="https://docs.promptlayer.com/features/faq#why-doesnt-my-evaluation-report-use-the-newest-version-of-my-prompt"
        className="text-blue-500 hover:text-blue-400"
        target="_blank"
        rel="noreferrer"
      >
        Learn how.
      </a>
    </div>
  );
};

export default Warning;
