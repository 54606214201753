import { ClockIcon } from "@heroicons/react/solid";
import { observer } from "mobx-react-lite";
import React from "react";
import { useWorkflow } from "../../workflow-context";
import mixpanel from "@/mixpanel";

const RunWorkflow: React.FC = () => {
  const workflow = useWorkflow();
  const { isRunning, nodeCount, isStarted } = workflow;

  const disabled = nodeCount === 0;

  const disabledStyle = (disabled && "opacity-50 cursor-not-allowed") || "";

  const buttonClasses = `
    flex items-center justify-center rounded-full bg-blue-600 px-4 py-2 text-white
    transition-colors duration-200 ease-in-out hover:bg-blue-700
    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
    pointer-events-auto text-sm ${disabledStyle}
  `;

  return (
    <button
      className={buttonClasses}
      aria-label="Run Agent"
      onClick={() => {
        mixpanel.track("Click Run Agent Button", {
          workflow_id: workflow.id,
          agent_name: workflow.name,
          node_count: nodeCount,
          version_number: workflow.versionNumber,
        });
        workflow.run();
      }}
      disabled={isRunning || disabled || isStarted}
    >
      {isRunning ? (
        <SpinnerIcon />
      ) : isStarted ? (
        <ClockIcon className="mr-2 h-5 w-5 animate-pulse" />
      ) : (
        <PlayIcon />
      )}
      <span>
        {isRunning ? "Running..." : isStarted ? "Starting..." : "Run Agent"}
      </span>
    </button>
  );
};

const SpinnerIcon: React.FC = () => (
  <svg
    className="mr-2 h-5 w-5 animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

const PlayIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="mr-2 h-5 w-5"
  >
    <path d="M8 5v14l11-7z" />
  </svg>
);

export default observer(RunWorkflow);
