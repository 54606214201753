import { useState } from "react";
import CommitMessageDialog from "./ui/CommitMessageDialog";
import { Button } from "./ui/button";

interface SaveWithCommitButtonProps {
  onSave?: (commitMessage: string) => void;
  onCancel?: () => void;
  name?: string;
  Extra?: React.ReactNode;
}

const SaveWithCommitButton = ({
  onSave,
  onCancel,
  Extra,
  name,
}: SaveWithCommitButtonProps) => {
  const [showCommitMessage, setShowCommitMessage] = useState(false);

  const handleSubmit = (commitMessage: string) => {
    setShowCommitMessage(false);
    if (onSave) onSave(commitMessage);
  };

  return (
    <>
      <Button
        size={"sm"}
        onClick={() => setShowCommitMessage(true)}
        className="flex items-center gap-2"
      >
        {name || "Save With Commit Message"}
      </Button>
      <CommitMessageDialog
        commitLabel="Workflow version change message (Optional)"
        title="Save New Workflow Version"
        saveTitle="Workflow Saved"
        onConfirm={(e) => handleSubmit(e!)}
        isOpen={showCommitMessage}
        onClose={() => {
          setShowCommitMessage(false);
        }}
      />
    </>
  );
};

export default SaveWithCommitButton;
