import { useCheckOverflow } from "@/hooks/useCheckOverflow";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./index";
import { BasicTooltipProps } from "./types";

export const OverflowDisplayTooltip = ({
  content,
  children,
  variant = "black",
  side = "top",
  usePortal = true,
  delayDuration,
  className,
}: BasicTooltipProps) => {
  const { ref, isOverflowing } = useCheckOverflow({});

  const renderContent = () => {
    return usePortal ? (
      <TooltipPortal>
        <TooltipContent side={side} variant={variant} style={{ zIndex: 9999 }}>
          {content}
        </TooltipContent>
      </TooltipPortal>
    ) : (
      <TooltipContent side={side} variant={variant} style={{ zIndex: 9999 }}>
        {content}
      </TooltipContent>
    );
  };

  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip>
        <TooltipTrigger className="truncate" asChild>
          <span className={className} ref={ref}>
            {children}
          </span>
        </TooltipTrigger>
        {isOverflowing && renderContent()}
      </Tooltip>
    </TooltipProvider>
  );
};
