import DatasetRowsFromFilterParamsModal from "@/components/DatasetRowsFromFilterParamsModal";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "lucide-react";
import { forwardRef, useState } from "react";
import { useParams } from "react-router-dom";

const AddFromRequestHistoryButton = forwardRef((props, ref) => {
  const [
    isDatasetRowsFromFilterParamsModalOpen,
    setDatasetRowsFromFilterParamsModalOpen,
  ] = useState(false);
  const { datasetId } = useParams();
  return (
    <>
      <Button
        onClick={() => setDatasetRowsFromFilterParamsModalOpen(true)}
        size="sm"
        variant="outline"
      >
        <PlusIcon aria-hidden="true" className="h-4 w-auto pr-1" />
        Add from Request History
      </Button>
      {datasetId && (
        <DatasetRowsFromFilterParamsModal
          dataset_id={parseInt(datasetId)}
          isOpen={isDatasetRowsFromFilterParamsModalOpen}
          setOpen={setDatasetRowsFromFilterParamsModalOpen}
        />
      )}
    </>
  );
});

export default AddFromRequestHistoryButton;
