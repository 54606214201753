export const resetPasswordFormFields = [
  { name: "email", label: "Email", placeholder: "Email", type: "text" },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    placeholder: "Confirm Password",
    type: "password",
  },
];

export const loginFormFields = [
  { name: "email", label: "Email", placeholder: "Email", type: "text" },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
  },
];
