import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import EvaluationSettings from "./EvaluationSettings";
import UpgradeRequired from "./UpgradeRequired";

export interface DropdownContentProps {
  evaluationId?: number;
  setEvaluationId: (report_id?: number) => void;
}

const DropdownContent = (props: DropdownContentProps) => {
  const activeWorkspace = useActiveWorkspace();
  const userCanCreateReport = !activeWorkspace?.admin_is_free;
  if (userCanCreateReport) return <EvaluationSettings {...props} />;
  return <UpgradeRequired />;
};

export default DropdownContent;
