import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useMetadataFields } from "@/queries";

import RestrictedKeyValuePair from "./Filter/RestrictedKeyValuePair";
import { MetadataFilterType } from "./types";

interface MetadataFilterProps {
  type?: MetadataFilterType;
  onUpdate?: (key: string, value: string[]) => void;
}

const MetadataFilter = ({
  type = MetadataFilterType.AND,
  onUpdate,
}: MetadataFilterProps) => {
  const auth = useAuth(),
    user = useUser();
  const metadataFieldsQuery = useMetadataFields(
    auth?.userToken!,
    user?.activeWorkspaceId,
  );

  const options = metadataFieldsQuery.data?.metadataFields?.map(
    (field: any) => field.name,
  );

  return (
    <>
      <div className="mb-2 flex items-center gap-2">
        <label className="text-sm font-medium text-gray-700">
          Metadata Filter
        </label>
        <span className="rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium">
          {type}
        </span>
      </div>
      {options && (
        <RestrictedKeyValuePair
          keys={options || []}
          onChange={(key, value) => {
            onUpdate?.(key, value);
          }}
        />
      )}
    </>
  );
};

export default MetadataFilter;
