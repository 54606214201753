import { NodeOutput } from "@/components/Workflows/types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { CalloutWithTitle } from "@/components/ui/callout";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNode } from "../../../node-context";
const formatJsonString = (value: unknown): string => {
  const jsonString = JSON.stringify(value, null, 2);
  try {
    const parsedJson = JSON.parse(jsonString);
    return JSON.stringify(parsedJson, null, 2);
  } catch (error) {
    return jsonString;
  }
};
const HumanReadableOutput = ({ output }: { output: NodeOutput }) => {
  const renderContent = () => {
    const { type, value } = output.display_value;

    if (type === "string") {
      return (
        <p className="max-w-[300px] whitespace-pre-wrap text-sm">{value}</p>
      );
    }

    return (
      <pre className="max-w-[300px] whitespace-pre-wrap break-words font-mono text-sm">
        {type === "object" && <span className="font-semibold">[JSON] </span>}
        {formatJsonString(value)}
      </pre>
    );
  };

  const hasRequestId =
    output.request_id !== undefined || output.trace_id !== undefined;
  const { workspaceId } = useParams();

  return (
    <CalloutWithTitle
      title={output.display_value.type.toUpperCase()}
      titleSize="text-sm"
      fullHeight
      actions={[
        hasRequestId && (
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size={"tinyIcon"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(
                      output.request_id
                        ? `/workspace/${workspaceId}/request/${output.request_id}`
                        : `/workspace/${workspaceId}/traces/${output.trace_id}`,
                      "_blank",
                    );
                  }}
                  className="group relative text-gray-600 hover:text-gray-800 focus:outline-none"
                  aria-label="Go to Request"
                >
                  🍰
                </Button>
              </TooltipTrigger>
              <TooltipContent
                side="top"
                align="center"
                sideOffset={5}
                className="rounded bg-gray-800 px-2 py-1 text-sm text-white"
              >
                Go to Request
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
      ]}
    >
      {renderContent()}
    </CalloutWithTitle>
  );
};

const NodeOutputs = () => {
  const node = useNode();
  const outputs: NodeOutput[] = node?.outputs || [];
  const [isOpen, setIsOpen] = useState(false);

  const sortedOutputs = [...outputs].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  if (outputs.length === 0) return null;

  return (
    <div className="-mb-3 w-full border-t border-gray-200 bg-gray-200 bg-opacity-20">
      <Accordion
        type="single"
        collapsible
        className="w-full"
        onValueChange={(value) => setIsOpen(value === "output")}
      >
        <AccordionItem value="output">
          <AccordionTrigger
            className="rounded-b-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 hover:bg-opacity-20 hover:text-gray-900"
            onClick={(e) => e.stopPropagation()}
          >
            Node Output ({outputs.length})
          </AccordionTrigger>
          <AccordionContent
            className="mx-auto w-full rounded-b-md border-t border-gray-100 px-4 py-3"
            style={{ pointerEvents: isOpen ? "auto" : "none" }}
          >
            <div className="nowheel max-h-[200px] space-y-4 overflow-y-auto">
              {sortedOutputs.map((output, index) => (
                <div key={index}>
                  <h4 className="mb-2 text-xs font-semibold text-gray-500">
                    Run #{sortedOutputs.length - index} -{" "}
                    {new Date(output.date).toLocaleString()}
                  </h4>
                  <div className="rounded bg-white px-4 py-2 shadow-sm">
                    <HumanReadableOutput output={output} />
                  </div>
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default observer(NodeOutputs);
