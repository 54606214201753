import LoadingSpinner from "@/components/LoadingSpinner";
import { PromptBlueprint } from "@/types";
import { useState } from "react";

import { Button } from "../button";
import { DialogFooter } from "../dialog";
import BlueprintDiffViewer from "./BlueprintDiffViewer";
import CommitMessage from "./CommitMessage";
import RunEvaluationDropdown from "./RunEvaluationDropdown";

interface CreateCommitMessageProps {
  data?: PromptBlueprint;
  promptName?: string;
  onClose: () => void;
  onConfirm: (commitMessage?: string, evaluationId?: number) => void;
  isLoading?: boolean;
  commitLabel?: string;
  onGoBack?: () => void;
  evaluationId?: number;
}

const CreateCommitMessage = ({
  data,
  promptName,
  onClose,
  onConfirm,
  isLoading,
  onGoBack,
  evaluationId: evaluationIdProp,
  commitLabel,
}: CreateCommitMessageProps) => {
  const [commitMessage, setCommitMessage] = useState("");
  const [evaluationId, setEvaluationId] = useState<number | undefined>(
    evaluationIdProp ?? undefined,
  );

  return (
    <div className="flex flex-col gap-y-4">
      <CommitMessage
        message={commitMessage}
        setMessage={setCommitMessage}
        label={commitLabel}
      />
      {promptName && (
        <RunEvaluationDropdown
          setEvaluationId={setEvaluationId}
          evaluationId={evaluationId || undefined}
          isEnabled={!!evaluationId}
        />
      )}
      {data && <BlueprintDiffViewer blueprint={data} promptName={promptName} />}
      <DialogFooter>
        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={onGoBack ? onGoBack : onClose}>
            {onGoBack ? "Back" : "Cancel"}
          </Button>
          <Button
            type="submit"
            onClick={() => onConfirm(commitMessage, evaluationId || undefined)}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <LoadingSpinner size={5} />
                Loading...
              </>
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </DialogFooter>
    </div>
  );
};

export default CreateCommitMessage;
