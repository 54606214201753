import clouds from "@/assets/clouds.png";
import {
  CloudUploadIcon,
  ExternalLinkIcon,
  FilmIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { AlertTriangleIcon } from "lucide-react";
import { useState } from "react";
import CardContainer from "./CardContainer";
import {
  BatchFeature,
  DeployingPromptsFeature,
  InstallingFeature,
  PromptTemplateFeature,
} from "./Features";
import { CalendlyModal } from "../CalendlyModal";

/* eslint-disable */
const MaintenanceBanner = ({
  date = "January 31st, 2024",
  startTime = "2:00",
}) => {
  return (
    <div className="relative w-full border-l-4 border-yellow-400 bg-yellow-50 px-4 py-3 text-gray-800  transition-colors duration-200">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <AlertTriangleIcon className="h-6 w-6 text-yellow-500" />
          <div>
            <p className="font-semibold text-gray-900">Scheduled Maintenance</p>
            <p className="text-sm text-gray-600">
              System maintenance scheduled for {date} starting at {startTime}.
              We expect no more than 3-5 minutes of downtime.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
/* eslint-enable */

export default function Home() {
  const [selectedCardHeading, setSelectedCardHeading] = useState<string | null>(
    null,
  );

  const handleCardClick = (heading: string) => {
    if (heading === selectedCardHeading) {
      setSelectedCardHeading(null);
    } else {
      setSelectedCardHeading(heading);
    }
  };

  const handleCloseClick = () => {
    setSelectedCardHeading(null);
  };

  const renderBanner = () => {
    return (
      <div className="relative mt-8 flex min-h-[160px] items-center justify-between overflow-visible rounded-lg bg-gray-100 p-4">
        <div className="self-start py-3">
          <h1 className="text-2xl font-medium">Welcome to PromptLayer 🍰</h1>
          <p className="mt-2 text-base font-light italic text-gray-500">
            Tell your friends you're a Prompt Engineer now!
          </p>
          <a
            className="mt-2 inline-block font-medium text-black underline"
            href="https://docs.promptlayer.com/"
            rel="noreferrer"
            target="_blank"
          >
            Read our docs
          </a>
          <ExternalLinkIcon
            aria-hidden="true"
            className="ml-2 inline-block h-4 w-4"
          />
        </div>
        <img
          alt="Clouds"
          className="absolute bottom-0 right-32"
          src={clouds}
          width={400}
        />
      </div>
    );
  };

  return (
    <div className="py-6">
      {/* <MaintenanceBanner
        date="December 15th, 2024"
        startTime="8 AM EST / 7 PM UTC"
      /> */}
      {renderBanner()}
      <div className="px-2">
        <CardContainer
          handleCardClick={handleCardClick}
          leftCardDetails={{
            buttonText: "Learn more",
            description:
              "Visually edit prompts in our dashboard. Compare versions and see when they are used.",
            detailComponent: (
              <PromptTemplateFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Create a prompt template",
            iconComponent: (
              <LightBulbIcon
                aria-hidden="true"
                className="h-6 w-6 text-blue-700"
              />
            ),
            subheading: "140k+ templates created",
          }}
          rightCardDetails={{
            buttonText: "Learn more",
            description:
              "Programmatically fetch your templates. Publish new prompts through our dashboard.",
            detailComponent: (
              <DeployingPromptsFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Deploy templates to production",
            iconComponent: (
              <CloudUploadIcon
                aria-hidden="true"
                className="h-6 w-6 text-blue-700"
              />
            ),
            subheading: "Avoid being blocked by eng releases",
          }}
          selectedCardHeading={selectedCardHeading}
        />

        <CardContainer
          handleCardClick={handleCardClick}
          leftCardDetails={{
            buttonText: "Learn more",
            description:
              "Setup PromptLayer with one line of code. Save all LLM requests and easily search for old ones.",
            detailComponent: (
              <InstallingFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Log your first request",
            iconComponent: (
              <FilmIcon aria-hidden="true" className="h-6 w-6 text-blue-700" />
            ),
            subheading: "40M+ requests logged",
          }}
          rightCardDetails={{
            buttonText: "Learn more",
            description:
              "Batch run prompts against sample input datasets. Find the best model and prompt for your use case.",
            detailComponent: (
              <BatchFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Evaluate your prompts",
            subheading: "900k+ tests run",
            iconComponent: (
              <QuestionMarkCircleIcon
                aria-hidden="true"
                className="h-6 w-6 text-blue-700"
              />
            ),
          }}
          selectedCardHeading={selectedCardHeading}
        />
        <CalendlyModal
          description="We’ll walk you through PromptLayer’s features & answer any questions you have."
          heading="One last thing..."
          url="https://calendly.com/jon-pedoeem/30min"
          title={
            <>
              Book your personalized onboarding call
              <span className="text-xl font-normal text-gray-400">
                {" "}
                (Optional)
              </span>
            </>
          }
          utm={{
            utmCampaign: "Schedule a call",
            utmSource: "onboarding",
          }}
        />
      </div>
    </div>
  );
}
