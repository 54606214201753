import { AuthContextType } from "@/context/auth-context";
import { jsonAuthHeaders } from "@/utils/headers";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { useMutation } from "@tanstack/react-query";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { ToastType } from "@/enums";

export const adminLogin = async ({
  admin_email,
  password,
  target_user_email,
}: {
  admin_email: string;
  password: string;
  target_user_email: string;
}): Promise<Response> => {
  return fetch(ENDPOINTS.admin_login, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      admin_email,
      password,
      target_user_email,
    }),
  });
};

export const useCreateAccount = () =>
  useMutation({
    mutationFn: async ({
      email,
      password,
      name,
      gclid,
    }: {
      email: string;
      password: string;
      name: string;
      gclid?: string;
    }) =>
      fetch(ENDPOINTS.create_account, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          name,
          gclid,
        }),
      }).then(handleResponse),
    onError: (error: any) => {
      displayErrorToast(
        typeof error === "string"
          ? error
          : "Unexpected server response format. Please try again later.",
      );
    },
  });

export const generateNewApiKey = (
  authContext: AuthContextType,
  workspaceId: number,
) => {
  if (!authContext) {
    return Promise.resolve(
      new Response(null, { status: 401, statusText: "Unauthorized" }),
    );
  }

  const userToken = authContext.userToken;

  return fetch(ENDPOINTS.create_api_key, {
    method: "POST",
    headers: jsonAuthHeaders(userToken!),
    body: JSON.stringify({
      workspace_id: workspaceId,
    }),
  });
};

export const useLogin = () =>
  useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) =>
      fetch(ENDPOINTS.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
        credentials: "include",
      }).then(handleResponse),
    onError: (error: any) => {
      displayErrorToast(
        typeof error === "string"
          ? error
          : "Unexpected server response format. Please try again later.",
      );
    },
  });

export const useForgotPassword = () =>
  useMutation({
    mutationFn: ({ email }: { email: string }) =>
      fetch(ENDPOINTS.forgot_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      }).then(handleResponse),
    onSuccess: (data: any) => {
      displayToast(data.message, ToastType.success);
    },
    onError: (error: any) => {
      displayErrorToast(
        typeof error === "string"
          ? error
          : "Unexpected server response format. Please try again later.",
      );
    },
  });

export const useResetPassword = () =>
  useMutation({
    mutationFn: async ({
      code,
      email,
      password,
    }: {
      code: string;
      email: string;
      password: string;
    }) =>
      fetch(ENDPOINTS.reset_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code,
          email,
          password,
        }),
      }).then(handleResponse),
    onError: (error: any) => {
      displayErrorToast(
        typeof error === "string"
          ? error
          : "Unexpected server response format. Please try again later.",
      );
    },
  });

export const toggleSharableRequest = (
  authContext: AuthContextType,
  id: number,
  newVal: boolean,
) => {
  const userToken = authContext.userToken;
  return fetch(ENDPOINTS.toggle_sharable_request, {
    method: "POST",
    headers: jsonAuthHeaders(userToken!),
    body: JSON.stringify({
      request_id: id,
      new_value: newVal,
    }),
  });
};

export const MeticulousProjectId =
  process.env.REACT_APP_METICULOUS_PROJECT_ID ||
  "CCPRmTSb6x60EJmZcuGb5CDc5rxQg45ISY0wTNRx";

export const API_URL =
  process.env.REACT_APP_API_URL_OVERRIDE || "https://api.promptlayer.com";

export const API_URL_WS =
  process.env.REACT_APP_API_URL_WS_OVERRIDE || "wss://api.promptlayer.com";

export const ENDPOINTS = {
  add_request_log_to_dataset: `${API_URL}/add-request-log-to-dataset`,
  get_workflow_edges: (workspace_id: number) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/edges`,
  get_api_keys: `${API_URL}/api-keys`,
  admin_login: `${API_URL}/admin-login`,
  available_release_labels: `${API_URL}/available-release-labels`,
  branch_prompt_template_version: `${API_URL}/branch-prompt-template-version`,
  comments: `${API_URL}/comments`,
  create_account: `${API_URL}/create-account`,
  user_onboarding: `${API_URL}/user-onboarding`,
  create_api_key: `${API_URL}/create-api-key`,
  create_application: `${API_URL}/create-application`,
  create_individual_run: `${API_URL}/create-individual-run`,
  create_stripe_checkout_session: `${API_URL}/create-stripe-checkout-session`,
  create_test_dataset: `${API_URL}/test-dataset-from-file`,
  dataset_column_to_json: `${API_URL}/dataset-column-to-json`,
  dataset_columns: `${API_URL}/dataset-columns`,
  dataset_groups: `${API_URL}/dataset-groups`,
  dataset_info: `${API_URL}/dataset-info`,
  dataset_rows: `${API_URL}/dataset-rows`,
  dataset_rows_from_filter_params: `${API_URL}/dataset-rows-from-filter-params`,
  datasets: `${API_URL}/datasets`,
  delete_api_key: `${API_URL}/delete-api-key`,
  delete_prompt_template: `${API_URL}/delete-prompt-template`,
  delete_user: `${API_URL}/delete-user`,
  draft_dataset_information: `${API_URL}/draft-dataset-information`,
  draft_dataset_state_from_dataset: `${API_URL}/draft-dataset-state-from-dataset`,
  duplicate_prompt_template_to_workspace: `${API_URL}/duplicate-prompt-template-to-workspace`,
  get_dynamic_release_labels_for_prompt: (
    workspace_id: number,
    prompt_id: string,
  ) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/prompt-registries/${prompt_id}/dynamic-release-labels`,
  final_reports: `${API_URL}/final-reports`,
  fine_tuned_job: `${API_URL}/fine-tuned-job`,
  fine_tuned_model: `${API_URL}/fine-tuned-model`,
  folders: `${API_URL}/folders`,
  forgot_password: `${API_URL}/forgot-password`,
  get_group_requests: (workspace_id: number, group_id: string) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/groups/${group_id}/requests`,
  get_groups: (workspace_id: number, request_id: string) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/requests/${request_id}/groups`,
  get_intercom_verification: `${API_URL}/get-intercom-verification`,
  get_provider_api_key_status: `${API_URL}/get-provider-api-key-status`,
  get_request_counts_for_versions: `${API_URL}/get-request-counts-for-versions`,
  get_request_stats_for_prompt: `${API_URL}/get-request-stats-for-prompt`,
  get_requests: `${API_URL}/get-requests`,
  get_requests_for_prompt: (
    workspace_id: number,
    prompt_id: string,
    version: string,
  ) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/prompt-registry-objects/${prompt_id}/prompt-versions/${version}/request-logs`,
  get_shared_request: `${API_URL}/get-shared-request`,
  get_user: `${API_URL}/get-user`,
  get_user_by_email: `${API_URL}/get-user-by-email`,
  get_user_subscription_status: `${API_URL}/get-user-subscription-status`,
  individual_run_requests: `${API_URL}/individual-run-requests`,
  list_applications: `${API_URL}/list-applications`,
  list_users: `${API_URL}/list-users`,
  login: `${API_URL}/login`,
  metadata_fields: `${API_URL}/metadata-fields`,
  move_prompt_template: `${API_URL}/move-prompt-template`,
  parse_input_variables: `${API_URL}/prompt-templates-input-variables`,
  prompt_labels: `${API_URL}/prompt-labels`,
  get_prompt_registry_objects: (workspace_id: number) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/prompt-registry-objects`,
  prompt_registry_objects: `${API_URL}/prompt-registry-objects`,
  prompt_registry_tags: `${API_URL}/prompt-registry/tags`,
  prompt_templates: `${API_URL}/prompt-templates`,
  prompt_versions: `${API_URL}/prompt-versions`,
  prompts: `${API_URL}/prompts`,
  get_prompt: `${API_URL}/api/v2/prompts`,
  provider_base_urls: `${API_URL}/provider-base-urls`,
  release_label_groups: `${API_URL}/release-label-groups`,
  report_cells: `${API_URL}/report-cells`,
  report_columns: `${API_URL}/report-columns`,
  reports: `${API_URL}/reports`,
  request_stats_by_metadata_field: `${API_URL}/request-stats-by-metadata-field`,
  request_stats_by_prompt: `${API_URL}/request-stats-by-prompt`,
  request_stats_by_tag: `${API_URL}/request-stats-by-tag`,
  reset_password: `${API_URL}/reset-password`,
  save_dataset: `${API_URL}/save-dataset`,
  score_names: `${API_URL}/score-names`,
  set_or_remove_azure_keys: `${API_URL}/set-or-remove-azure-keys`,
  set_or_remove_bedrock_keys: `${API_URL}/set-or-remove-bedrock-keys`,
  set_or_remove_provider_api_key: `${API_URL}/set-or-remove-provider-api-key`,
  set_user_to_free_plan: `${API_URL}/set-user-to-free-plan`,
  spans: `${API_URL}/spans`,
  statistics_page: `${API_URL}/statistics-page`,
  tags: `${API_URL}/tags`,
  threads: `${API_URL}/threads`,
  toggle_sharable_request: `${API_URL}/toggle-sharable-request`,
  toggle_starred_request: `${API_URL}/toggle-starred-request`,
  trace: `${API_URL}/trace`,
  track_metadata: `${API_URL}/rest/track-metadata`,
  track_score: `${API_URL}/track-score`,
  update_tags: `${API_URL}/update-tags`,
  upload: `${API_URL}/upload`,
  usage: `${API_URL}/usage`,
  webhooks: `${API_URL}/webhooks`,
  workspace_invite: `${API_URL}/workspace-invite`,
  workspace_members: `${API_URL}/workspace-members`,
  workspaces: `${API_URL}/workspaces`,
  ws: `${API_URL_WS}`,
  ws_token_request: `${API_URL}/ws-token-request`,
  backtests: `${API_URL}/backtests`,
  ab_tests: `${API_URL}/ab-tests`,
  verify_dynamic_release_label_existence: `${API_URL}/verify-dynamic-release-label-existence`,
  file_upload_multipart: `${API_URL}/file-upload-multipart`,
  workflow_nodes: `${API_URL}/workflow-nodes`,
  workflow_version_executions: `${API_URL}/workflow-version-executions`,
  workflow_versions: `${API_URL}/workflow-versions`,
  workflows: `${API_URL}/workflows`,
  workflow_editor_state: `${API_URL}/workflow-editor-state`,
  workflow_play_from_here: `${API_URL}/workflow-play-from-here`,
  workflow_labels: `${API_URL}/workflow-labels`,
  available_workflow_release_labels: `${API_URL}/available-workflow-release-labels`,
  workflow_release_label_groups: `${API_URL}/workflow-release-label-groups`,
  workflow_editor_input_variables: `${API_URL}/workflow-editor-input-variables`,
  inference_clients: `${API_URL}/inference_clients`,
  search_dataset_rows: `${API_URL}/search-dataset-rows`,
  create_dataset_from_evaluation: `${API_URL}/create-dataset-from-evaluation`,
  new_requests: `${API_URL}/new-requests`,
  new_spans: `${API_URL}/new-spans`,
  report_columns_include_prompt_template_latest_version: `${API_URL}/report-columns-include-prompt-template-latest-version`,
  request_logs: `${API_URL}/request-logs`,
  get_prompt_version_scores: (workspace_id: number, prompt_id: string) =>
    `${API_URL}/api/dashboard/v2/workspaces/${workspace_id}/prompt-templates/${prompt_id}/version-evaluation-scores`,
  request_log_input_variables: (id: number) =>
    `${API_URL}/request-log/${id}/input-variables`,
};
