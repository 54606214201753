import { ScoreCard } from "@/components/Evaluate/ScoreMatrix/types";
import {
  ChatIcon,
  CodeIcon,
  CubeIcon,
  GlobeAltIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

import {
  ArrowUpDownIcon,
  BracesIcon,
  BrainIcon,
  CalculatorIcon,
  CheckCircleIcon,
  CompassIcon,
  DatabaseIcon,
  FileTextIcon,
  HashIcon,
  LinkIcon,
  ListTree,
  MergeIcon,
  RegexIcon,
  RulerIcon,
  ScaleIcon,
  ScissorsIcon,
  UserIcon,
  Workflow,
} from "lucide-react";
export type LanguageType = "PYTHON" | "JAVASCRIPT";
export interface CodeExecutionConfiguration {
  code: string;
  language: LanguageType;
}

export type Report = {
  created_at: string;
  dataset_id: number;
  id: number;
  is_blueprint: boolean;
  name: string;
  test_dataset_id: number | null;
  updated_at: string;
  user_id: number;
  workspace_id: number;
  parent_report_id: number | null;
  comment: string | null;
  score: null | ReportScore;
  score_calculation_error?: string;
  score_configuration?: {
    code: string;
    code_language: LanguageType;
  } | null;
  score_matrix?: ScoreCard[];
  deleted: boolean;
  status?: ReportStatus;
  prompt_version_number: number | null;
  prompt_registry_id: number | null;
  columns: ReportColumn[]; // Columns are not included by default, but some endpoints can include them
};

export type ReportStatus = {
  COMPLETED: number;
  FAILED: number;
  QUEUED: number;
  RUNNING: number;
};

export type ReportScore =
  | {
      type: "number";
      value: number;
      num_skipped?: number;
    }
  | {
      type: "boolean";
      value: number; // % of true
      num_skipped?: number;
    };

export enum ColumnType {
  DATASET = "DATASET",
  PROMPT_TEMPLATE = "PROMPT_TEMPLATE",
  ENDPOINT = "ENDPOINT",
  HUMAN = "HUMAN",
  COMPARE = "COMPARE",
  COALESCE = "COALESCE",
  ABSOLUTE_NUMERIC_DISTANCE = "ABSOLUTE_NUMERIC_DISTANCE",
  REGEX = "REGEX",
  JSON_PATH = "JSON_PATH",
  PARSE_VALUE = "PARSE_VALUE",
  CONTAINS = "CONTAINS",
  LLM_ASSERTION = "LLM_ASSERTION",
  VARIABLE = "VARIABLE",
  ASSERT_VALID = "ASSERT_VALID",
  COSINE_SIMILARITY = "COSINE_SIMILARITY",
  COUNT = "COUNT",
  MATH_OPERATOR = "MATH_OPERATOR",
  XML_PATH = "XML_PATH",
  REGEX_EXTRACTION = "REGEX_EXTRACTION",
  COMBINE_COLUMNS = "COMBINE_COLUMNS",
  MIN_MAX = "MIN_MAX",
  CODE_EXECUTION = "CODE_EXECUTION",
  WORKFLOW = "WORKFLOW",
  PENDING = "PENDING",
}

export const ColumnTypeIcon: { [key in ColumnType]: React.ComponentType } = {
  [ColumnType.DATASET]: DatabaseIcon,
  [ColumnType.PROMPT_TEMPLATE]: ChatIcon,
  [ColumnType.ENDPOINT]: GlobeAltIcon,
  [ColumnType.HUMAN]: UserIcon,
  [ColumnType.COMPARE]: ScaleIcon,
  [ColumnType.COALESCE]: MergeIcon,
  [ColumnType.ABSOLUTE_NUMERIC_DISTANCE]: RulerIcon,
  [ColumnType.REGEX]: RegexIcon,
  [ColumnType.JSON_PATH]: BracesIcon,
  [ColumnType.PARSE_VALUE]: FileTextIcon,
  [ColumnType.CONTAINS]: SearchIcon,
  [ColumnType.LLM_ASSERTION]: BrainIcon,
  [ColumnType.VARIABLE]: CubeIcon,
  [ColumnType.ASSERT_VALID]: CheckCircleIcon,
  [ColumnType.COSINE_SIMILARITY]: CompassIcon,
  [ColumnType.COUNT]: HashIcon,
  [ColumnType.MATH_OPERATOR]: CalculatorIcon,
  [ColumnType.XML_PATH]: ListTree,
  [ColumnType.REGEX_EXTRACTION]: ScissorsIcon,
  [ColumnType.COMBINE_COLUMNS]: LinkIcon,
  [ColumnType.MIN_MAX]: ArrowUpDownIcon,
  [ColumnType.CODE_EXECUTION]: CodeIcon,
  [ColumnType.WORKFLOW]: Workflow,
  [ColumnType.PENDING]: QuestionMarkCircledIcon,
};

export type ReportColumn = {
  column_type: ColumnType;
  configuration: any;
  id?: number; // when we are creating a new report column, we don't have an ID yet
  name: string;
  position: number;
  report_id: number;
  errors?: {
    invalid_columns: string[];
    message: string;
    type: string;
  }[];
  is_part_of_score: boolean;
  score: ReportScore | null;
};

export type PromptTemplateConfiguration = {
  template: {
    name: string;
    version_number?: number | null; // null means latest
    label?: string;
  };
  engine?: {
    // optional, if not provided, pull from prompt registry at runtime
    provider: string;
    provider_base_url_name: string | null;
    model: string;
    parameters: {
      [key: string]: any;
    };
  };
  prompt_template_variable_mappings: {
    [key: string]: string;
  };
};

export type EndpointConfiguration = {
  url: string;
};

export type CompareConfiguration = {
  sources: [string, string];
  comparison_type?: // optional for backward compatability, default to STRING
  | {
        type: "STRING";
      }
    | {
        type: "JSON";
        json_path: string | null; // if null, compare the entire JSON object
      };
};

export type AbsoluteNumericDistanceConfiguration = {
  sources: [string, string];
};

export type RegexConfiguration = {
  regex_pattern: string;
  source: string;
};

export type JsonPathConfiguration = {
  source: string;
  json_path: string;
  return_first_match: boolean;
};

export type ParseValueConfiguration = {
  type: "string" | "number" | "boolean" | "object";
  source: string;
};

export type ContainsConfiguration = {
  source: string;
  value: string;
};

export type LLMAssertionConfiguration = {
  prompt?: string;
  source: string;
  prompt_source?: string;
};

interface StringValue {
  type: "string";
  value: string;
}

interface JsonValue {
  type: "json";
  value: object;
}

type Value = StringValue | JsonValue;

export type VariableConfiguration = {
  value: Value;
};

export type AssertValidConfiguration = {
  source: string;
  type: "number" | "object" | "sql";
};

export type CosineSimilarityConfiguration = {
  sources: [string, string];
};

export type CountConfiguration = {
  source: string;
  type: "chars" | "words" | "sentences" | "paragraphs";
};

export type MathOperatorConfiguration = {
  operator: "lt" | "le" | "gt" | "ge";
  value: number | null;
  sources: [string, string | null];
};

export type XmlPathConfiguration = {
  source: string;
  xml_path: string;
  type: "find" | "findall";
  return_text?: boolean;
};

export type RegexExtractionConfiguration = {
  regex_pattern: string;
  source: string;
};

export type CombineColumnsConfiguration = {
  sources: Array<string>;
};

export const minMaxTypes = ["min", "max"] as const;

export type MinMaxType = (typeof minMaxTypes)[number];

export type MinMaxConfiguration = {
  json_path?: string;
  type: MinMaxType;
  source: string;
};

export type WorkflowConfiguration = {
  name: string;
  workflow_label_name?: string;
  workflow_version_number?: number;
  metadata?: { [key: string]: string };
  input_variables?: { [key: string]: any };
  return_all_outputs?: boolean;
};

export type CoalesceConfiguration = {
  sources: string[];
};

export type HumanConfiguration =
  | {
      data_type: "number";
      ui_element: SliderUIElement | TextboxUIElement;
    }
  | {
      data_type: "string";
      ui_element: TextboxUIElement;
    };

export type SliderUIElement = {
  component: "slider";
  min: number;
  max: number;
};

export type TextboxUIElement = {
  component: "text";
};

interface ToolCall {
  id: string;
  function: {
    arguments: string;
    name: string;
  };
  type: string;
}

export type ReportCell = {
  id: number;
  report_column_id: number;
  status: "QUEUED" | "COMPLETED" | "FAILED" | "RUNNING";
  dataset_row_id: number; // Row ID
  value: {
    value: any;
  };
  display_value:
    | {
        type: "string";
        value: string;
      }
    | {
        type: "number";
        value: number;
      }
    | {
        type: "function";
        value: {
          name: string;
          arguments: string;
        };
      }
    | {
        type: "tool_calls";
        value: ToolCall[];
      }
    | {
        type: "object";
        value: object;
      }
    | {
        type: "unknown";
        value: any;
      }
    | {
        type: "boolean";
        value: boolean;
      };
  error_message: string | null;
};

export type ReportColumnStats = {
  report_cell_id: number;
  request_latency: number;
  request_price: number;
  request_input_tokens: number;
  request_output_tokens: number;
}[];
