import { z } from "zod";

export const loginSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email format" })
    .nonempty({ message: "Email is required" }),
  password: z.string().nonempty({ message: "Password is required" }),
});

export const resetPasswordSchema = z
  .object({
    email: z
      .string()
      .email({ message: "Invalid email format" })
      .nonempty({ message: "Email is required" }),
    password: z
      .string()
      .nonempty({ message: "Password is required" })
      .regex(
        /^(?=\S{6,100}$)(?=.*?\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^A-Za-z\s0-9])/,
        {
          message:
            "Password must be at least 6 characters, have 1 digit, 1 lowercase, 1 uppercase, and 1 special character",
        },
      ),
    confirmPassword: z
      .string()
      .nonempty({ message: "Please verify your password" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });
