import { Toggle } from "@/components/Toggle";
import { useState } from "react";
import { DropdownContentProps } from "..";
import SelectEvaluation from "./SelectEvaluation";

type EvaluationSettingsProps = DropdownContentProps & {
  onToggle?: (value: boolean) => void;
};

const EvaluationSettings = ({
  evaluationId,
  setEvaluationId,
  onToggle,
}: EvaluationSettingsProps) => {
  const [runEvaluation, setRunEvaluation] = useState(!!evaluationId);
  const [toggleEvaluation, setToggleEvaluation] = useState(runEvaluation);
  const handleEnabled = (value: boolean) => {
    onToggle?.(value);
    setToggleEvaluation(value);
    setEvaluationId(value ? evaluationId : undefined);
    setSelectedEvaluation({ id: value ? evaluationId : null, name: false });
    setRunEvaluation(value);
  };
  const [selectedEvaluation, setSelectedEvaluation] = useState<any>({
    id: evaluationId,
    name: false,
  });

  return (
    <div
      className={`relative my-4 grid grid-cols-3 items-center gap-y-4 border-t border-gray-200 px-3 pt-6 text-sm text-gray-600`}
    >
      <label htmlFor="preview-name" className="col-span-1">
        Trigger evaluation:
      </label>
      <div className="col-span-2 flex items-center">
        <Toggle enabled={toggleEvaluation} setEnabled={handleEnabled} />
      </div>
      {toggleEvaluation && (
        <SelectEvaluation
          onSelect={(evaluation) => {
            setSelectedEvaluation(evaluation);
            setEvaluationId(evaluation.id);
          }}
          selectedEvaluation={selectedEvaluation}
        />
      )}
    </div>
  );
};
//<SelectReport />
export default EvaluationSettings;
