import { OverflowDisplayTooltip } from "../../Tooltip/OverflowDisplayTooltip";

import { Button } from "../../ui/button";
import { DialogFooter } from "../../ui/dialog";

import { NavigationProps } from "./types";

export const Navigation = ({
  setOpen,
  handleGoBack,
  currentFolder,
  selectedFolderId,
  promptFolderId,
  handleMove,
}: NavigationProps) => {
  return (
    <DialogFooter className="flex !justify-between">
      <Button
        className="bg-transparent text-sm"
        onClick={() => setOpen(false)}
        variant="destructiveOutline"
      >
        Cancel
      </Button>
      <div className="flex gap-2">
        <Button
          className="bg-transparent text-sm"
          onClick={handleGoBack}
          variant="outline"
          disabled={!currentFolder}
        >
          Go Back
        </Button>
        <Button
          className="max-w-[190px] overflow-hidden"
          disabled={selectedFolderId === promptFolderId}
          onClick={handleMove}
        >
          <OverflowDisplayTooltip
            delayDuration={300}
            content={`Move to ${!currentFolder ? "Home" : currentFolder?.name}`}
          >
            {`Move to ${!currentFolder ? "Home" : currentFolder?.name}`}
          </OverflowDisplayTooltip>
        </Button>
      </div>
    </DialogFooter>
  );
};
