import folderGraphic from "@/assets/folder.svg";
import { PromptRegistry } from "@/types/prompt-registry";

import { FolderPath } from "../MoveToFolderModal/FolderPath";
import { DropdownMenuItem } from "../ui/dropdown-menu";

const FolderGroup = ({ folder, templates, handleTemplateSelection }: any) => (
  <div className="py-1">
    <div className="flex gap-x-2 px-1 pb-1 text-xs uppercase text-gray-600">
      <img
        alt="Folder graphic"
        className="w-4 text-blue-600"
        src={folderGraphic}
      />
      <FolderPath topLevelFolder={folder.name} />
    </div>
    <div className="my-2 ml-1 border-l border-gray-200 pl-1">
      {templates
        .sort((a: PromptRegistry, b: PromptRegistry) =>
          a.prompt_name.localeCompare(b.prompt_name),
        )
        .map((template: PromptRegistry) => (
          <DropdownMenuItem
            key={template.prompt_name}
            onSelect={() =>
              handleTemplateSelection({
                id: template.id,
                name: template.prompt_name,
              })
            }
          >
            {template.prompt_name}
          </DropdownMenuItem>
        ))}
    </div>
  </div>
);

export default FolderGroup;
