import { PromptBlueprint } from "@/types";
import { PromptVersion } from "@/types/apiGetters";
import { Navigate } from "react-router-dom";
import AnimatedCheckIcon from "../animated-check-icon";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../dialog";
import CreateCommitMessage from "./CreateCommitMessage";
import RunTests from "./RunTests";
import useNavigateToPromptVersion from "./hooks/useNavigateToPromptVersion";
import useRedirectLogic from "./hooks/useRedirectLogic";

interface CommitMessageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (commitMessage?: string, evaluationId?: number) => void;
  title?: string;
  saveTitle?: string;
  data?: PromptBlueprint;
  prompt?: {
    name?: string;
    id?: number;
  };
  isLoading?: boolean;
  showGoBack?: boolean;
  onGoBack?: () => void;
  commitLabel?: string;
  submittedVersion?: PromptVersion;
  evaluationId?: number;
}

const CommitMessageDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  title = "Save New Prompt Version",
  saveTitle = "Prompt Template Saved",
  commitLabel = "Prompt version change message (Optional)",
  data,
  prompt,
  onGoBack,
  evaluationId,
  submittedVersion,
}: CommitMessageDialogProps) => {
  const { shouldShowTestContent, link } = useRedirectLogic(
    prompt,
    submittedVersion,
  );

  const redirect = useNavigateToPromptVersion(submittedVersion!);

  if (prompt && submittedVersion && isOpen && !shouldShowTestContent)
    return <Navigate to={link} replace />;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>
            {submittedVersion ? (
              <div className="my-8 flex flex-col items-center gap-y-4">
                <AnimatedCheckIcon />
                <h2 className="text-2xl font-bold text-gray-800">
                  {saveTitle}
                </h2>
              </div>
            ) : (
              title
            )}
          </DialogTitle>
        </DialogHeader>
        {submittedVersion ? (
          <RunTests
            submittedPromptVersion={submittedVersion}
            onClose={redirect}
          />
        ) : (
          <CreateCommitMessage
            data={data}
            evaluationId={evaluationId}
            promptName={prompt?.name}
            onClose={onClose}
            onConfirm={onConfirm}
            isLoading={isLoading}
            onGoBack={onGoBack}
            commitLabel={commitLabel}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CommitMessageDialog;
