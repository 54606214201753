import { cn } from "@/lib/utils";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from "react";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = ({ ...props }) => (
  <TooltipPrimitive.Root delayDuration={0} {...props} />
);

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    variant?: "black" | "blue";
  }
>(({ className, sideOffset = 4, variant = "black", ...props }, ref) => (
  <AnimatePresence>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      align="center"
      side={props.side}
      alignOffset={0}
      avoidCollisions={true}
      collisionPadding={8}
      {...props}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.96, y: 2 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.96, y: 2 }}
        transition={{
          type: "spring",
          damping: 20,
          stiffness: 350,
          duration: 0.2,
        }}
      >
        <div
          className={cn(
            "z-[9999] overflow-hidden rounded-md px-3 py-1.5 text-xs shadow-sm",
            variant === "black" && "bg-black text-white",
            variant === "blue" && "bg-blue-500 text-white",
            className,
          )}
        >
          <div>{props.children}</div>
          <TooltipPrimitive.Arrow
            className={cn(
              variant === "black" && "fill-black",
              variant === "blue" && "fill-blue-500",
            )}
          />
        </div>
      </motion.div>
    </TooltipPrimitive.Content>
  </AnimatePresence>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface BasicTooltipProps {
  content: ReactNode;
  children: ReactNode;
  variant?: "black" | "blue";
}

const BasicTooltip = forwardRef<
  HTMLDivElement,
  BasicTooltipProps & {
    side?: "top" | "bottom" | "left" | "right";
    usePortal?: boolean;
  }
>(
  (
    { content, children, variant = "black", side = "top", usePortal = true },
    ref,
  ) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        {usePortal ? (
          <TooltipPrimitive.Portal>
            <TooltipContent
              ref={ref}
              side={side}
              variant={variant}
              style={{
                zIndex: 9999,
                maxWidth: "300px",
                maxHeight: "400px",

                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              <div
                style={{
                  maxHeight: "400px",
                  overflow: "auto",
                  borderRadius: "inherit",
                }}
              >
                {content}
              </div>
            </TooltipContent>
          </TooltipPrimitive.Portal>
        ) : (
          <TooltipContent ref={ref} side={side} variant={variant}>
            {content}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  ),
);

BasicTooltip.displayName = "BasicTooltip";

export {
  BasicTooltip,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
};
