import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useGetBlueprints } from "@/queries";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState } from "react";
import EvaluationTriggerText from "../../EvaluationTriggerText";
import Warning from "./Warning";

interface SelectEvaluationProps {
  onSelect: (evaluation: { id: number; name: string }) => void;
  selectedEvaluation: { id: number; name: string } | null;
}

const SelectEvaluation = ({
  onSelect,
  selectedEvaluation,
}: SelectEvaluationProps) => {
  const auth = useAuth();
  const user = useUser();
  const userToken = auth?.userToken!;
  const workspaceId = user?.activeWorkspaceId!;
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetBlueprints(
    userToken,
    workspaceId,
    page,
    10,
    null,
    true,
  );

  const hasNextPage = page < data?.pages!;
  const totalPages = data?.pages;

  const handleNextPage = () => hasNextPage && setPage(page + 1);

  const handlePrevPage = () => page > 1 && setPage(page - 1);

  return (
    <>
      <label htmlFor="preview-name" className="col-span-1">
        Evaluation Pipeline:
      </label>
      <DropdownMenu>
        <DropdownMenuTrigger className="col-span-2">
          {selectedEvaluation?.name || (
            <EvaluationTriggerText evaluationId={selectedEvaluation?.id} />
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {isLoading ? (
            <DropdownMenuItem>Loading...</DropdownMenuItem>
          ) : (
            <>
              {data?.reports.map((blueprint) => (
                <DropdownMenuItem
                  key={blueprint.id}
                  onSelect={() =>
                    onSelect({ id: blueprint.id, name: blueprint.name })
                  }
                >
                  {blueprint.name}
                </DropdownMenuItem>
              ))}
              <DropdownMenuItem
                className="flex justify-between border-t border-gray-200 pt-2"
                onSelect={(e) => e.preventDefault()}
              >
                <button
                  onClick={handlePrevPage}
                  disabled={page === 1}
                  className="text-blue-500 hover:text-blue-700 disabled:cursor-not-allowed disabled:text-gray-400"
                >
                  &larr; Previous
                </button>
                <span className="text-sm text-gray-500">
                  Page {page} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={!hasNextPage}
                  className="text-blue-500 hover:text-blue-700 disabled:cursor-not-allowed disabled:text-gray-400"
                >
                  Next &rarr;
                </button>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <div className="col-span-1">&nbsp;</div>
      <div className="col-span-2">
        <Warning
          promptName={"Not implemented"}
          evaluationId={selectedEvaluation?.id}
        />
      </div>
    </>
  );
};

export default SelectEvaluation;
