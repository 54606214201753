import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useScoreNames } from "@/queries";
import RestrictedComparion from "./Filter/RestrictedComparison";

interface ScoreFilterProps {
  onUpdate: (key: string, operator: string, value: string) => void;
}

const ScoreFilter: React.FC<ScoreFilterProps> = ({ onUpdate }) => {
  const auth = useAuth(),
    user = useUser();
  const names = useScoreNames(auth?.userToken!, user?.activeWorkspaceId);

  const options = names.data?.scoreNames;

  const handleChange = (key: string, operator: string, value: string) => {
    onUpdate(key, operator, value);
  };

  return (
    <div>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        Score
      </label>
      <RestrictedComparion keys={options} onChange={handleChange} />
    </div>
  );
};

export default ScoreFilter;
