import { EyeClosedIcon } from "@radix-ui/react-icons";
import { EyeIcon } from "lucide-react";
import { forwardRef, useState } from "react";
import { FormInputProps } from "./types";
import { Input } from "../input";

export const FormInput = forwardRef(
  (
    { name, label, placeholder, type, error, ...rest }: FormInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="flex flex-col gap-1.5">
        <label htmlFor={name} className="text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="relative flex w-full">
          <Input
            {...rest}
            className="w-full rounded-lg border border-gray-300 px-3.5 py-2.5 placeholder:text-base placeholder:font-light placeholder:text-gray-500"
            type={showPassword ? "text" : type}
            placeholder={placeholder}
            id={name}
            name={name}
            ref={ref}
          />
          {type === "password" && (
            <div
              className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray-400 hover:text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeClosedIcon className="h-5 w-5" />
              ) : (
                <EyeIcon className="h-5 w-5" />
              )}
            </div>
          )}
        </div>
        {error && <div className="text-sm text-red-500">{error}</div>}
      </div>
    );
  },
);
