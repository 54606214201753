import { Folder } from "@/types/folders";
import { FolderPath } from "./FolderPath";
import { CheckIcon } from "@radix-ui/react-icons";
import { FolderIcon } from "@heroicons/react/solid";

export const FolderItem = ({
  folder,
  selectedFolderId,
  handleSelectFolder,
}: {
  folder: Folder;
  selectedFolderId: number | null;
  handleSelectFolder: (folderId: number) => void;
}) => {
  return (
    <div
      className="hover:bg-gray-10 flex h-12 cursor-pointer items-center gap-2 rounded-md px-2 hover:bg-gray-50 active:bg-gray-100"
      key={folder.id}
      onClick={() => handleSelectFolder(folder.id)}
    >
      <FolderIcon className="h-8 w-8 text-gray-300" />
      <div className="flex items-center">
        <FolderPath topLevelFolder={folder.name} />
        {selectedFolderId === folder.id && (
          <CheckIcon className="h-6 w-6 text-blue-500" />
        )}
      </div>
    </div>
  );
};
