import LoadingSpinner from "@/components/LoadingSpinner";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateBacktest } from "@/queries";
import { PromptVersion } from "@/types/apiGetters";
import { displayErrorToast } from "@/utils/toast";
import { BarChart2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import useNavigateToPromptVersion from "../hooks/useNavigateToPromptVersion";

interface BackTestProps {
  promptVersion: PromptVersion;
  setError: (error: string) => void;
}

const BackTest = ({ promptVersion, setError }: BackTestProps) => {
  const navigate = useNavigate();
  const navigateToPromptVersion = useNavigateToPromptVersion(promptVersion);

  const user = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = user?.activeWorkspaceId!;
  const backTest = useCreateBacktest(userToken);
  const isLoading = backTest.isLoading;

  const handleBackTestClick = () => {
    backTest.mutate(
      {
        promptVersionId: promptVersion.id,
        workspaceId,
      },
      {
        onSuccess: (res: {
          success?: boolean;
          report_id?: string;
          error?: string;
        }) => {
          if (res?.success && res?.report_id) {
            navigate(
              `/workspace/${workspaceId}/evaluate/reports/${res.report_id}`,
            );
          } else {
            setError(
              res?.error ?? "There's a problem with the backtest request",
            );
          }
        },
        onError: () => {
          navigateToPromptVersion();
          displayErrorToast("There's a problem with the backtest request");
        },
      },
    );
  };

  return (
    <button
      disabled={isLoading}
      onClick={handleBackTestClick}
      className="flex flex-1 flex-col items-center justify-center rounded-lg border-2 border-blue-200 p-6 text-blue-800 transition duration-300 hover:bg-blue-50"
    >
      {backTest.isLoading ? (
        <LoadingSpinner size={5} />
      ) : (
        <>
          <BarChart2 className="mb-2 h-12 w-12" />
          <span className="mb-2 font-semibold">Run a backtest</span>
          <p className="text-center text-sm text-gray-600">
            Evaluate performance with historical data
          </p>
        </>
      )}
    </button>
  );
};

export default BackTest;
