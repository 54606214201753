import { XIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "../../button";
import { BasicTooltip } from "../../tooltip";

interface FilterProps {
  children: React.ReactNode;
  onRemove: () => void;
}

const Filter: React.FC<FilterProps> = ({ children, onRemove }) => {
  return (
    <div className="relative rounded-md border bg-white p-4 shadow-sm">
      <BasicTooltip content="Remove filter">
        <Button
          variant={"ghost"}
          size={"tinyIcon"}
          onClick={onRemove}
          className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
        >
          <XIcon className="h-4 w-4" />
        </Button>
      </BasicTooltip>
      {children}
    </div>
  );
};
export default observer(Filter);
