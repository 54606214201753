import { z } from "zod";

export const createAccountSchema = z
  .object({
    email: z
      .string()
      .email({ message: "Invalid email format" })
      .nonempty({ message: "Email is required" }),
    name: z.string().nonempty({ message: "Name is required" }),
    password: z
      .string()
      .nonempty({ message: "Password is required" })
      .regex(
        /^(?=\S{6,100}$)(?=.*?\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^A-Za-z\s0-9])/,
        {
          message:
            "Password must be at least 6 characters, have 1 digit, 1 lowercase, 1 uppercase, and 1 special character",
        },
      ),
    verifyPassword: z
      .string()
      .nonempty({ message: "Please verify your password" }),
  })
  .refine((data) => data.password === data.verifyPassword, {
    message: "Passwords do not match",
    path: ["verifyPassword"],
  });
