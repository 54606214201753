import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import { useState } from "react";
import DropdownContent from "./DropdownContent";
import EvaluationTriggerText from "./EvaluationTriggerText";

interface RunEvaluationDropdownProps {
  title?: string;
  isEnabled?: boolean;
  toggleLabel?: {
    on: string;
    off: string;
  };
  onToggle?: (isEnabled: boolean) => void;
  setEvaluationId: (evaluationId?: number) => void;
  evaluationId?: number;
}

const RunEvaluationDropdown = ({
  title = "Do you want to run an evaluation?",
  isEnabled = false,
  toggleLabel = { on: "On", off: "Off" },
  onToggle,
  setEvaluationId,
  evaluationId,
}: RunEvaluationDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <div className="mx-2 rounded-sm border border-gray-300 bg-gray-50 px-3 py-2 shadow-sm">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <p className="text-sm text-gray-700">{title}</p>
            <span
              className={`rounded-full px-3 py-1 text-xs ${
                isEnabled
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-100"
              }`}
              onClick={() => onToggle?.(!isEnabled)}
            >
              {isEnabled ? toggleLabel.on : toggleLabel.off}
            </span>
            {isEnabled && (
              <span className="truncate text-xs text-gray-400">
                <EvaluationTriggerText evaluationId={evaluationId} />
              </span>
            )}
          </div>
          <CollapsibleTrigger asChild>
            <button className="text-xs text-blue-600 hover:text-blue-500">
              {isOpen ? "Hide" : "Edit"}
            </button>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent>
          <DropdownContent
            evaluationId={evaluationId}
            setEvaluationId={setEvaluationId}
          />
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
};

export default RunEvaluationDropdown;
