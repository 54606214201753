import LoadingSpinner from "@/components/LoadingSpinner";
import { cn } from "@/lib/utils";
import { Split } from "lucide-react";
import { forwardRef } from "react";

const ABTestButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }
>(({ className, isLoading, ...props }, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className={cn(
        "flex aspect-square flex-1 flex-col items-center justify-center rounded-lg border-2 border-purple-200 p-6 text-purple-800 transition duration-300 hover:bg-purple-50",
        className,
      )}
    >
      {isLoading ? (
        <LoadingSpinner size={5} />
      ) : (
        <>
          <Split className="mb-2 h-12 w-12" />
          <span className="mb-2 font-semibold">Start A/B test</span>
          <p className="text-center text-sm text-gray-600">
            Compare performance with current version
          </p>
        </>
      )}
    </button>
  );
});

export default ABTestButton;
