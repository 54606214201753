import { X } from "lucide-react";
import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";

interface TagInputProps {
  initialTags: string[];
  onTagsChange: (tags: string[]) => void;
}

const TagInput: FC<TagInputProps> = ({ initialTags, onTagsChange }) => {
  const [input, setInput] = useState("");
  const [items, setItems] = useState<string[]>(initialTags);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const prevItemsRef = useRef<string[]>([]);

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(prevItemsRef.current)) {
      onTagsChange(items);
      prevItemsRef.current = items;
    }
  }, [items, onTagsChange]);

  const addItem = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      if (
        items.some((item) => item.toLowerCase() === trimmedValue.toLowerCase())
      ) {
        return;
      }
      setItems((prevItems) => [...prevItems, trimmedValue]);
    }
  };

  const addItems = (inputValue: string) => {
    const newItems = inputValue
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item !== "");

    newItems.forEach(addItem);
    setInput("");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
      case ",":
        e.preventDefault();
        if (selectedIndex >= 0) {
          removeItem(selectedIndex);
        } else {
          addItems(input);
        }
        break;
      case "ArrowLeft":
        if (selectedIndex === -1 && input === "" && items.length > 0) {
          setSelectedIndex(items.length - 1);
        } else {
          setSelectedIndex((prev) => Math.max(0, prev - 1));
        }
        break;
      case "ArrowRight":
        if (selectedIndex >= 0) {
          setSelectedIndex((prev) => (prev < items.length - 1 ? prev + 1 : -1));
        }
        break;
      case "Backspace":
        if (input === "" && items.length > 0 && selectedIndex === -1) {
          setSelectedIndex(items.length - 1);
        } else if (selectedIndex >= 0) {
          removeItem(selectedIndex);
        }
        break;
      default:
        if (selectedIndex !== -1) {
          setSelectedIndex(-1);
        }
        break;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    addItems(pastedText);
  };

  const removeItem = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    setSelectedIndex(-1);
  };

  const renderItems = () => {
    return items.map((item, index) => (
      <span
        className={`flex items-center rounded-full border border-gray-300 bg-gray-50 px-2 py-1 font-mono text-xs
                        ${
                          selectedIndex === index ? "ring-2 ring-blue-500" : ""
                        }`}
        key={index}
      >
        {item}
        <button
          className="ml-2 text-gray-600 hover:text-red-500"
          onClick={() => removeItem(index)}
          type="button"
        >
          <X size={16} />
        </button>
      </span>
    ));
  };

  return (
    <div className="mt-1 w-full">
      <div className=" flex flex-wrap items-center gap-2 rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm">
        {renderItems()}
        <input
          className="min-w-[300px] flex-1 rounded border-none p-0 text-sm outline-none focus:outline-none focus:ring-0"
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder="Press enter or , to add item"
          ref={inputRef}
          type="text"
          value={input}
        />
      </div>
    </div>
  );
};

export default TagInput;
