import { useEffect } from "react";

import { useMemo } from "react";

import { Folder } from "@/types/folders";

import { useFolders } from "@/queries";

import { useMovePromptTemplate } from "@/queries";

import { useUser } from "@/context/user-context";

import { useAuth } from "@/context/auth-context";

import { useState } from "react";
import { formatFoldersWithSubFolders } from "../utils";
import { displayToast } from "@/utils/toast";
import { ToastType } from "@/enums";
import { Breadcrumb } from "../FolderBreadcrumbs/types";
import { PromptRegistry } from "@/types/prompt-registry";

const buildBreadcrumbs = (
  folderList: Folder[],
  currentFolder?: Folder,
  acc?: { name: string; id: number; parent_id: number }[],
) => {
  const parentFolder = folderList.find(
    (f) => f.id === currentFolder?.parent_id,
  );
  const childFolders = currentFolder?.subFolders.map((f) => ({
    name: f.name,
    id: f.id,
    parent_id: f.parent_id,
  }));

  if (parentFolder && childFolders) {
    acc = [
      {
        name: parentFolder.name,
        id: parentFolder.id,
        parent_id: parentFolder.parent_id,
      },
      {
        name: currentFolder?.name ?? "",
        id: currentFolder?.id ?? 0,
        parent_id: currentFolder?.parent_id ?? 0,
      },
      ...(acc || []),
    ];
  } else {
    return acc;
  }

  return buildBreadcrumbs(folderList, parentFolder, acc);
};

export const useMoveToFolderModal = (
  prompt: PromptRegistry | null,
  setOpen: (open: boolean) => void,
) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState<number | null>(
    prompt?.folder_id ?? null,
  );
  const authContext = useAuth();
  const userContext = useUser();
  const workspaceId = userContext.activeWorkspaceId!;
  const userToken = authContext!.userToken;
  const movePromptTemplate = useMovePromptTemplate(userToken!);
  const foldersQuery = useFolders(userToken!, workspaceId!);
  const [currentFolder, setCurrentFolder] = useState<Folder | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const folderList = useMemo(
    (): Folder[] => formatFoldersWithSubFolders(foldersQuery.data.folders),
    [foldersQuery.data?.folders],
  );

  useEffect(() => {
    if (selectedFolderId && folderList.length > 0) {
      const selectedFolder = folderList.find(
        (folder: Folder) => folder.id === selectedFolderId,
      );

      if (!selectedFolder) {
        return;
      }

      if (selectedFolder?.id === prompt?.folder_id) {
        const newBreadcrumbs = buildBreadcrumbs(folderList, selectedFolder);
        setBreadcrumbs(newBreadcrumbs ?? []);
        setCurrentFolder(selectedFolder ?? null);
      }
    }
  }, [selectedFolderId, folderList, prompt?.folder_id]);

  const handleSelectFolder = (folderId: number) => {
    setSelectedFolderId(folderId);

    const newCurrentFolder = folderList.find((f) => f.id === folderId);
    setCurrentFolder(newCurrentFolder ?? null);

    if (searchTerm) {
      const newBreadcrumbs = buildBreadcrumbs(folderList, newCurrentFolder);
      setBreadcrumbs(newBreadcrumbs ?? []);
      setSearchTerm("");

      return;
    }

    setBreadcrumbs((prev) => [
      ...prev,
      {
        name: newCurrentFolder?.name ?? "",
        id: folderId,
        parent_id: newCurrentFolder?.parent_id ?? 0,
      },
    ]);
  };

  const handleGoBack = () => {
    const previousFolder = folderList.find((f) => {
      if (f.id === currentFolder?.parent_id) {
        return f;
      }

      return f.subFolders.find((sf) => sf.id === currentFolder?.parent_id);
    });

    setCurrentFolder(previousFolder ?? null);
    setSelectedFolderId(previousFolder?.id ?? null);
    setBreadcrumbs((prev) => prev.slice(0, -1));
  };

  const handleMove = async () => {
    if (!prompt) {
      return;
    }

    try {
      await movePromptTemplate.mutateAsync({
        folder_id: selectedFolderId,
        prompt_template_id: prompt.id.toString(),
      });
      displayToast(
        `Template moved to ${currentFolder?.name}`,
        ToastType.success,
      );
      setOpen(false);
    } catch (error: unknown) {
      displayToast("Failed to move template", ToastType.error);
    }
  };

  return {
    selectedFolderId,
    handleSelectFolder,
    handleGoBack,
    handleMove,
    searchTerm,
    setSearchTerm,
    currentFolder,
    breadcrumbs,
    folderList,
    setBreadcrumbs,
    setCurrentFolder,
    setSelectedFolderId,
  };
};
