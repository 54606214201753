import { FC } from "react";
import noiseBackground from "@/assets/noise-background.png";

interface AuthSubmitButtonProps {
  loading: boolean;
  text: string;
  disabled?: boolean;
}

const AuthSubmitButton: FC<AuthSubmitButtonProps> = ({
  loading,
  text,
  disabled,
}) => {
  const buttonContent = loading ? "Loading..." : text;

  return (
    <button
      className={`mt-6 w-full rounded-lg px-[18px] py-2.5 text-white phone:mt-2 ${
        loading || disabled
          ? "bg-gray-400"
          : "border border-[#1D4ED8] bg-[#1D4ED8] shadow-sm hover:bg-[#1D4ED8]/90"
      }`}
      disabled={loading || disabled}
      type="submit"
      style={{
        backgroundImage: `url(${noiseBackground})`,
        backgroundRepeat: "repeat",
        backgroundBlendMode: "overlay",
        backgroundSize: "contain",
      }}
    >
      {buttonContent}
    </button>
  );
};

export default AuthSubmitButton;
