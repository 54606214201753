import EmptyPlaceholder from "@/components/ui/EmptyPlaceholder";
import { PlusIcon } from "@heroicons/react/outline";
import { CurlyBracesIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useSchemaEditor } from ".";
import { Button } from "../ui/button";
import SchemaMetadata from "./SchemaMetadata";
import SchemaParameter from "./SchemaParameter";
const NonStandardSchemaView = () => {
  return (
    <div className="mx-auto flex w-full max-w-[500px] flex-1 flex-col gap-y-4 p-4">
      <div className="rounded-lg border border-yellow-500 bg-white p-4">
        <div className="mb-4">
          <h3 className="mb-1 text-lg font-medium text-gray-900">
            Unknown JSON Schema
          </h3>
          <p>
            Non-standard JSON schema detected. Please double-check your entry or
            continue editing manually.{" "}
            <Button
              variant="link"
              className="pl-0"
              onClick={() =>
                window.open(
                  "https://json-schema.org/overview/what-is-jsonschema",
                  "_blank",
                )
              }
            >
              Learn more
            </Button>
          </p>
        </div>

        <div className="rounded-lg bg-gray-50 p-4">
          <div className="flex flex-col gap-2 rounded bg-white p-4">
            <div className="h-4 w-3/4 rounded bg-gray-200" />
            <div className="h-4 w-1/2 rounded bg-gray-200" />
            <div className="ml-4 h-4 w-2/3 rounded bg-gray-200" />
            <div className="ml-8 h-4 w-1/3 rounded bg-gray-200" />
            <div className="ml-8 h-4 w-1/4 rounded bg-gray-200" />
          </div>
        </div>
      </div>
    </div>
  );
};

const InteractiveEditor = () => {
  const schemaEditorStore = useSchemaEditor();
  const isDisabled = !schemaEditorStore.isStandardSchema;

  if (isDisabled) {
    return <NonStandardSchemaView />;
  }

  const parameters = (
    <>
      {(schemaEditorStore.schemaParameters.length > 0 &&
        schemaEditorStore.schemaParameters.map((parameter, index) => (
          <SchemaParameter key={index} parameter={parameter} />
        ))) || (
        <EmptyPlaceholder
          message="No parameters defined yet"
          icon={<CurlyBracesIcon className="h-5 w-5 text-gray-400" />}
        />
      )}
    </>
  );

  return (
    <div className="flex flex-1 flex-col gap-y-6 overflow-x-auto">
      <div className="relative w-full pb-12">
        <SchemaMetadata />
        <div className="mt-6 w-full">
          <h4 className="mb-2 text-sm font-medium">Schema Parameters</h4>
          <div className="overflow-x-auto px-1">{parameters}</div>
        </div>
        {!schemaEditorStore.isReadonly && (
          <Button
            className="mt-3"
            variant={"secondaryOutline"}
            onClick={() => {
              schemaEditorStore.addParameter();
            }}
          >
            <PlusIcon className="mr-1 h-3 w-3" /> Add Parameter
          </Button>
        )}
      </div>
    </div>
  );
};

export default observer(InteractiveEditor);
